
import { mapGetters } from 'vuex'

export default {
  async asyncData ({ app }) {
    const response = await app.$cacheFetch(
      {
        key: `get:/client/${app.$config.storeId}/${app.i18n.locale}/${app.store.state.country.iso_code}/pages/about`,
        expire: app.$config.responseCacheLifetime
      },
      async () => {
        return await app.$axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/pages/about`)
      }
    )

    return {
      page: response.data
    }
  },

  data () {
    return {

    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      logo: 'logo'
    }),

    breadcrumbs () {
      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.page.header,
          to: '#',
          active: true
        }
      ]
    }
  },

  head () {
    return {
      title: this.page.title,
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: `${this.$config.appUrl}/${this.page.canonical}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.description
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.page.schema_org_title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.schema_org_description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.page.robots
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.page.keywords
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: this.page.schemaOrg.about,
          body: true
        }
      ]
    }
  }
}
