
import { mapGetters } from 'vuex'

import Faq from '@/components/Faq'

export default {
  components: {
    Faq
  },

  props: {
    category: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    page: 1
  }),

  computed: {
    ...mapGetters({
      country: 'country'
    }),

    breadcrumbs () {
      const length = this.category.breadcrumbs.length

      this.category.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = length === (index + 1)
      })

      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.category.breadcrumbs)
    }
  },

  methods: {
    productsByCategory (category) {
      let length
      const categoriesLength = this.category.productsByCategory.length

      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 4 : 8
      } else if (['tablet'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 6 : 12
      } else if (['desktop'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 9 : 12
      } else if (['widescreen'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 8 : 12
      } else {
        length = 12
      }

      return category.products.slice(0, length)
    }
  }
}
