
import ProductPartialsVotes from '@/components/product/partials/Votes'

export default {
  components: {
    ProductPartialsVotes
  },

  props: {
    type: {
      type: String,
      required: true
    },
    comments: {
      type: Array,
      required: true
    },
    commentsCount: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    show: true
  }),

  computed: {
    howMuchMoreComments () {
      return this.commentsCount - this.comments.length
    }
  },

  methods: {
    toggleComments () {
      this.show = !this.show
    },

    getDiffFromDateFormatted (date) {
      return this.$moment.duration(
        this.$moment(date).locale(this.$i18n.locale).diff(this.$moment().locale(this.$i18n.locale))
      ).humanize(true)
    },

    loadMore () {

    }
  }
}
