
export default {
  async asyncData ({ $axios, app, route, error, redirect }) {
    try {
      const model = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/pages${route.path}`)

      let params

      if (model.data.redirect.page.slug.includes('/')) {
        const slugs = model.data.redirect.page.slug.split('/')

        params = {
          slug: slugs[0],
          params: slugs[1]
        }
      } else {
        params = {
          slug: model.data.redirect.page.slug
        }
      }

      redirect(model.data.redirect.type, app.localePath({ name: 'slug', params }))
    } catch (e) {
      error({ statusCode: 404 })
    }
  }
}
