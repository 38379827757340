
import { mapGetters } from 'vuex'

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

Vue.use(VueTelInput)

export default {
  mixins: [
    vueTelInputAllCountries
  ],

  data () {
    return {
      notification: {
        active: false,
        message: '',
        class: ''
      },
      form: {
        phone: {
          number: '',
          model: '',
          valid: false,
          error: '',
          focus: false,
          country: undefined
        },
        password: '',
        show_password: false,
        loading: false,
        sent: false
      },
      error: ''
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      compare: 'compare/compare',
      country: 'country',
      countries: 'countries'
    }),

    formIsValid () {
      return this.form.phone.valid
    }
  },

  created () {
    this.form.phone.country = this.country
  },

  methods: {
    onPhoneInput (number, phone) {
      this.form.phone.model = phone.number

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.form.phone.valid = true
        this.form.phone.error = ''
      } else {
        this.form.phone.valid = false
        this.form.phone.error = this.$t('pages.checkout.user.phone.errors.invalid')
      }
    },

    send () {
      this.form.loading = true

      const data = {
        phone: this.form.phone.model
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/auth/password/forgot`, data)
        .then((res) => {
          this.notification.message = res.message
          this.notification.class = 'is-success'
          this.notification.active = true
          this.form.sent = true
        })
        .finally(() => {
          this.form.loading = false

          this.$refs.password.focus()
        })
    },

    login () {
      this.form.loading = true

      if (!this.form.phone.valid) {
        return
      }

      this.$auth.loginWith('laravelJWT', {
        data: {
          login: this.form.phone.model,
          password: this.form.password,
          remember: false,
          cart: this.cart.id ? this.cart : null,
          compare: this.compare.id ? this.compare : null,
          country: this.country
        }
      })
        .then(() => {
          while (!this.$auth.loggedIn) {
            // Just in case auth not set
          }

          let compareSynced = this.compare.items.concat(this.$auth.user.compare.items)
          const compareSeen = Object.create(null)
          compareSynced = [...new Set([...this.compare.items, ...this.$auth.user.compare.items])].filter((o) => {
            const key = ['class', 'compareable_id'].map(k => o[k]).join('|')
            if (!compareSeen[key]) {
              compareSeen[key] = true
              return true
            }
          })

          this.$store.dispatch('compare/setCompare', {
            id: this.$auth.user.compare.id,
            items: compareSynced
          })

          this.$store.dispatch('compare/syncCompare')

          let cartSynced = this.cart.items.concat(this.$auth.user.cart.items)
          const cartSeen = Object.create(null)
          cartSynced = [...new Set([...this.cart.items, ...this.$auth.user.cart.items])].filter((o) => {
            const key = ['class', 'cartable_id'].map(k => o[k]).join('|')
            if (!cartSeen[key]) {
              cartSeen[key] = true
              return true
            }
          })

          this.$store.dispatch('cart/setCart', {
            id: this.$auth.user.cart.id,
            items: cartSynced
          })

          this.$store.dispatch('cart/syncCart')

          this.$emit('modalActiveToggle', false)
        })
        .catch((e) => {
          // this.error = e.response.data.message || this.$t('pages.error.other')
        })
        .finally((e) => {
          this.form.loading = false

          this.$router.replace(this.localePath({ name: 'user-password' }))
        })
    }
  }
}
