
import { mapGetters } from 'vuex'

export default {
  props: {
    store: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      globalStore: 'store'
    })
  },

  methods: {
    generateSchedule (schedule, storeSchedule = false) {
      const processedSchedule = []
      const vm = this

      if (schedule.holiday) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday() - 1
        const storeScheduleDays = this.globalStore.schedule.days.slice(weekday).concat(schedule.days.slice(0, weekday))

        schedule.days.forEach((day, index) => { day.index = index })

        schedule.days.slice(weekday).concat(schedule.days.slice(0, weekday)).forEach((day, index) => {
          const open = storeSchedule && (storeScheduleDays[index].open === null || storeScheduleDays[index].open > day.open) ? storeScheduleDays[index].open : day.open
          const close = storeSchedule && (storeScheduleDays[index].close === null || storeScheduleDays[index].close < day.close) ? storeScheduleDays[index].close : day.close

          const i = processedSchedule.findIndex((x, key) => x.open === open && x.close === close && key === processedSchedule.length - 1)

          if (i === -1) {
            processedSchedule.push({
              days: vm.$moment(day.index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase(),
              open,
              close
            })
          } else {
            processedSchedule[i].days = `${processedSchedule[i].days.split('–')[0]}–${vm.$moment(day.index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase()}`
          }
        })
      } else {
        const storeScheduleDays = this.globalStore.schedule.days

        schedule.days.forEach(function (day, index) {
          const open = storeSchedule && (storeScheduleDays[index].open === null || storeScheduleDays[index].open > day.open) ? storeScheduleDays[index].open : day.open
          const close = storeSchedule && (storeScheduleDays[index].close === null || storeScheduleDays[index].close < day.close) ? storeScheduleDays[index].close : day.close

          const i = processedSchedule.findIndex((x, key) => x.open === open && x.close === close && key === processedSchedule.length - 1)

          if (i === -1) {
            processedSchedule.push({
              days: vm.$moment(index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase(),
              open,
              close
            })
          } else {
            processedSchedule[i].days = `${processedSchedule[i].days.split('–')[0]}–${vm.$moment(index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase()}`
          }
        })
      }

      return processedSchedule
    },

    scheduleHasMessage (schedule) {
      return schedule.message && schedule.message.replace(/(<([^>]+)>)/gi, '') !== ''
    }
  }
}
