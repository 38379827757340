import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6c7ae6a6&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notification: require('/app/components/Notification.vue').default,HeaderNavTop: require('/app/components/header/NavTop.vue').default,HeaderNavBottom: require('/app/components/header/NavBottom.vue').default})
