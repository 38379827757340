
import { mapGetters } from 'vuex'

import UserSideMenu from '@/components/user/SideMenu'

export default {
  components: {
    UserSideMenu
  },

  middleware: 'auth',

  async asyncData ({ params, $axios, app }) {
    const address = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/addresses/${params.address}`)

    return {
      address: address.data
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: this.localePath('user'),
          active: false
        },
        {
          title: this.$t('pages.user.addresses.title'),
          to: this.localePath('user-addresses'),
          active: false
        },
        {
          title: this.$t('pages.user.addresses.edit.title', { address: this.$route.params.address }),
          to: '#',
          active: true
        }
      ],
      notification: {
        active: false,
        message: '',
        class: ''
      },
      cities: {
        items: [],
        major: [],
        loading: false
      },
      city: {
        searching: false,
        query: '',
        isActiveDropdown: false,
        name: '',
        error: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  mounted () {
    this.fetchMajorCities()
  },

  methods: {
    hideCityDropDown () {
      this.city.isActiveDropdown = false
    },

    toggleCitySelect () {
      this.city.isActiveDropdown = !this.city.isActiveDropdown

      this.$nextTick(() => {
        this.$refs.city.focus()
      })
    },

    changeCity (city) {
      this.address.city = city
      this.city.query = this.address.city.name
      this.hideCityDropDown()
    },

    async searchCity () {
      this.cities.loading = true
      if (this.city.query && this.city.query.length < 3) {
        return
      } else if (this.city.query === '') {
        this.cities.items = []
        return
      }

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/cities?q=${this.city.query}`)
        .then((res) => {
          this.cities.items = res.data
        })

      this.cities.loading = false
    },

    clearCity () {
      this.cities.items = []
      this.city.query = ''
      this.address.city = {
        id: '',
        name: ''
      }
    },

    async fetchMajorCities () {
      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/cities/major`)
        .then((res) => {
          this.cities.major = res.data
        })
    },

    update () {
      this.$axios.$put(`${this.$i18n.locale}/${this.country.iso_code}/user/addresses/${this.address.id}`, this.address)
        .then((res) => {
          this.notification.active = true
          this.notification.message = res.message
          this.notification.class = 'is-success'
        })
    }
  },

  head () {
    return {
      title: this.$t('pages.user.addresses.edit.title', { address: this.address.title }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.addresses.edit.description')
        }
      ]
    }
  }
}
