
import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown)

export default {
  props: {
    banner: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    currency: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  computed: {
    link () {
      if (this.banner.bannerable.page.slug.includes('/')) {
        const slugs = this.banner.bannerable.page.slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug: this.banner.bannerable.page.slug } })
      }
    }
  },

  methods: {
    background (banner) {
      if (this.$mq === 'fullhd' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.fullhd
      } else if (this.$mq === 'widescreen' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.widescreen
      } else if (this.$mq === 'desktop' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.desktop
      } else if (this.$mq === 'tablet' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.tablet
      } else if (this.$mq === 'mobile' && this.$mq in banner.backgrounds) {
        return banner.backgrounds.mobile
      } else {
        return banner.backgrounds.mobileSmall
      }
    }
  }
}
