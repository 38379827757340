
export default {
  props: {
    condition: {
      required: true,
      default () {
        return 5
      },
      type: Number
    }
  }
}
