
export default {
  props: {
    hiddenMobile: {
      required: true,
      default () {
        return true
      },
      type: Boolean
    }
  }
}
