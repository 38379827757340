
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

import location from '@/mixins/location.js'

// import ContactsGoogleMap from '@/components/contacts/GoogleMap'

export default {
  components: {
    // ContactsGoogleMap,
    LazyModalsLocationModal: defineAsyncComponent(() => import('@/components/modals/LocationModal'))
  },

  mixins: [
    location
  ],

  data: () => ({
    isActive: false
  }),

  computed: {
    ...mapGetters({
      location: 'location'
    })
  },

  // mounted () {
  //   if (this.location.city === null || this.location.city.id === null) {
  //     this.getLocation()
  //   }
  // },

  methods: {
    toggleModal () {
      this.isActive = !this.isActive

      // this.getLocation()
    }
  }
}
