
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    active: false
  }),

  computed: {
    ...mapGetters({
      support: 'support/support'
    })
  },

  created () {
    if (!this.getGDPR() === true) {
      this.active = true
    }
  },

  methods: {
    getGDPR () {
      if (process.client) {
        return localStorage.getItem('GDPR:accepted', true)
      }
    },

    accept () {
      if (process.client) {
        this.active = false
        localStorage.setItem('GDPR:accepted', true)
      }
    },

    deny () {
      if (process.client) {
        this.active = false
        localStorage.setItem('GDPR:accepted', false)
      }
    }
  }
}
