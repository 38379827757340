
import { mapGetters } from 'vuex'

import filters from '@/mixins/filters.js'

import ProductCard from '@/components/product/Card'
import LoadMore from '@/components/LoadMore'

export default {
  components: {
    ProductCard,
    LoadMore
  },

  mixins: [
    filters
  ],

  async asyncData ({ params, $axios, app, route, error }) {
    try {
      const search = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/search/${params.params}` + (route.query.page ? `?page=${route.query.page}` : ''))

      await app.store.dispatch('i18n/setRouteParams', search.data.alternate)

      if (search.data.queries.length > 0) {
        app.store.dispatch('search/setQueries', search.data.queries)
      }

      return {
        search: search.data
      }
    } catch (e) {
      error({ statusCode: 404 })
    }
  },

  data () {
    return {
      path: {
        name: 'search',
        params: {
          //
        }
      },
      pathWithParams: {
        name: 'search-params',
        params: {
          params: undefined
        }
      },
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.search.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency',
      logo: 'logo'
    })
  },

  created () {
    if (this.$route.params.params) {
      this.params = this.$route.params.params

      this.params.split('_').forEach((param) => {
        const values = param.split('--')
        const filterSlug = values.shift()

        if (filterSlug === 'q') {
          this.filters.push({
            key: filterSlug,
            values
          })
        }

        this.initializeConditionStatusChosen(filterSlug, values, this.search.statuses.condition)
        this.initializeStockStatusChosen(filterSlug, values, this.search.statuses.stock)
        this.initializeBadgesChosen(filterSlug, values, this.search.badges)
        this.initializeCategoryChosen(filterSlug, values, Object.values(this.search.categories).flat())
        this.initializePricesChosen(filterSlug, values)
        this.initializeSortChosen(filterSlug, values)
      })
    }

    this.initializeSortDefault()

    this.setInitialPage(this.search.pages)
  },

  methods: {
    async loadMoreProducts () {
      if (this.page >= this.search.pages) {
        return
      }

      this.page += 1
      this.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/search/products/${this.params}?page=${this.page}`)
        .then((res) => {
          this.reachedLastPage = this.page === this.search.pages
          this.search.products = [...this.search.products, ...res.data]

          // history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?page=${this.page}`)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  head () {
    return {
      title: this.$t('pages.search.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.search.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.search.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.search.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        }
      ]
    }
  }
}
