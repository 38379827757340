
import { defineAsyncComponent } from '@vue/composition-api'

export default {
  components: {
    LazyModalsAuthModal: defineAsyncComponent(() => import('@/components/modals/AuthModal'))
  },

  props: {
    hiddenMobile: {
      required: true,
      default () {
        return true
      },
      type: Boolean
    }
  },

  data: () => ({
    modal: {
      active: false
    }
  }),

  methods: {
    toggleModal (active = !this.modal.active) {
      this.modal.active = active
    }
  }
}
