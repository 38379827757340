
import AuthForgot from '@/components/auth/Forgot'
import AuthLogin from '@/components/auth/Login'
import AuthRegister from '@/components/auth/Register'

export default {
  components: {
    AuthForgot,
    AuthLogin,
    AuthRegister
  },

  data: () => ({
    modal: {
      type: 'login'
    }
  }),

  methods: {
    setModalType (type) {
      this.modal.type = type
    }
  }
}
