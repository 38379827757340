
import UserSideMenu from '@/components/user/SideMenu'
// import Orderable from '@/components/Orderable'
import OrderDelivery from '@/components/order/Delivery'
import OrderPayment from '@/components/order/Payment'

export default {
  components: {
    UserSideMenu,
    // Orderable,
    OrderDelivery,
    OrderPayment
  },

  middleware: 'auth',

  async asyncData ({ params, $axios, app }) {
    const order = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/orders/${params.order}`)

    return {
      order: order.data
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: this.localePath('user'),
          active: false
        },
        {
          title: this.$t('pages.user.orders.title'),
          to: this.localePath('user-orders'),
          active: false
        },
        {
          title: this.$t('pages.user.orders.order.title', { order: this.$route.params.order }),
          to: '#',
          active: true
        }
      ]
    }
  },

  head () {
    return {
      title: this.$t('pages.user.orders.order.title', { order: this.order.id }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.orders.order.description')
        }
      ]
    }
  }
}
