
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import ButtonsAuth from '@/components/buttons/Auth'
import ButtonsCompare from '@/components/buttons/Compare'
import ButtonsCart from '@/components/buttons/Cart'
import ButtonsSearch from '@/components/buttons/Search'
import MenusCatalogMenu from '@/components/menus/CatalogMenu'
import MenusMobileMenu from '@/components/menus/MobileMenu'
import Logo from '@/components/Logo'

export default {
  components: {
    ButtonsAuth,
    ButtonsCompare,
    ButtonsCart,
    ButtonsSearch,
    MenusCatalogMenu,
    MenusMobileMenu,
    Logo
  },

  mixins: [
    clickaway
  ],

  data: () => ({
    lastScrollTop: 0,
    sticky: true,
    searchIsHiddenMobile: true
  }),

  computed: {
    ...mapGetters({
      notification: 'notification'
    })
  },

  mounted () {
    window.addEventListener('scroll', this.onScroll, { passive: true })
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll (e) {
      const st = window.scrollY || document.documentElement.scrollTop

      if (st > this.lastScrollTop) {
        this.sticky = false
      } else if (st < this.lastScrollTop) {
        this.sticky = true
      }

      const productsTab = document.getElementById('product-tabs')

      if (productsTab) {
        productsTab.style.top = !this.sticky ? 0 : ''
      }

      this.lastScrollTop = st <= 0 ? 0 : st
    },

    toggleSearch () {
      const html = document.documentElement
      html.style.overflow = null

      this.searchIsHiddenMobile = !this.searchIsHiddenMobile

      this.$nextTick(() => {
        this.$refs.search.$el.getElementsByClassName('input')[0].focus()
      })
    },

    away () {
      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        this.searchIsHiddenMobile = true
      }
    },

    isIndex () {
      return this.$route.path === this.localePath('index')
    }
  }
}
