import { render, staticRenderFns } from "./_params.vue?vue&type=template&id=57b3a379&"
import script from "./_params.vue?vue&type=script&lang=js&"
export * from "./_params.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/app/components/Breadcrumbs.vue').default,FiltersTouchscreenButtons: require('/app/components/filters/TouchscreenButtons.vue').default,FiltersSortDropdown: require('/app/components/filters/SortDropdown.vue').default,FiltersChosen: require('/app/components/filters/Chosen.vue').default,FiltersPrice: require('/app/components/filters/Price.vue').default,FiltersBadges: require('/app/components/filters/Badges.vue').default,FiltersStockStatus: require('/app/components/filters/StockStatus.vue').default,FiltersConditionStatus: require('/app/components/filters/ConditionStatus.vue').default,FiltersCategories: require('/app/components/filters/Categories.vue').default,ProductCard: require('/app/components/product/Card.vue').default,LoadMore: require('/app/components/LoadMore.vue').default})
