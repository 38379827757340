
import { mapGetters } from 'vuex'

export default {
  props: {
    model: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      compare: 'compare/compare',
      country: 'country'
    }),

    inCompare () {
      return this.compare.items.find(item => item.compareable_id === this.model.id)
    }
  },

  methods: {
    toggleCompare (item) {
      this.$store.dispatch('compare/toggleCompare', { item })

      this.$emit('compared', {
        compares: this.compare
      })
    }
  }
}
