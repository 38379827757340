
import { mapGetters } from 'vuex'

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import TextareaAutosize from 'vue-textarea-autosize'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

Vue.use(VueTelInput)
Vue.use(TextareaAutosize)

export default {
  mixins: [
    vueTelInputAllCountries
  ],

  data: () => ({
    notification: {
      active: false,
      message: '',
      class: ''
    },
    loading: false,
    user: {
      phone: {
        focus: false,
        country: undefined
      }
    },
    whatTheFuck: false
  }),

  computed: {
    ...mapGetters({
      country: 'country',
      countries: 'countries',
      currency: 'currency',
      notifyAboutProductAvailability: 'modal/notifyAboutProductAvailability'
    }),

    userNameValue: {
      get () {
        return this.notifyAboutProductAvailability.user.name.value
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserNameValue', value)
      }
    },

    userLastnameValue: {
      get () {
        return this.notifyAboutProductAvailability.user.lastname.value
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserLastnameValue', value)
      }
    },

    userPatronymicValue: {
      get () {
        return this.notifyAboutProductAvailability.user.patronymic.value
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserPatronymicValue', value)
      }
    },

    userPhoneModel: {
      get () {
        return this.notifyAboutProductAvailability.user.phone.model
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserPhoneModel', value)
      }
    },

    userEmailValue: {
      get () {
        return this.notifyAboutProductAvailability.user.email.value
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserEmailValue', value)
      }
    },

    noteBody: {
      get () {
        return this.notifyAboutProductAvailability.note.body
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetNoteBody', value)
      }
    },

    callback: {
      get () {
        return this.notifyAboutProductAvailability.callback
      },
      set (value) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetCallback', value)
      }
    },

    userIsValid () {
      return !this.notifyAboutProductAvailability.user.name.error &&
        this.notifyAboutProductAvailability.user.name.value &&
        !this.notifyAboutProductAvailability.user.phone.error &&
        this.notifyAboutProductAvailability.user.phone.number &&
        !this.notifyAboutProductAvailability.user.email.error // &&
        // this.notifyAboutProductAvailability.user.email.value
    }
  },

  created () {
    this.user.phone.country = this.country
  },

  methods: {
    validateUserNameOnKeydown () {
      if (this.notifyAboutProductAvailability.user.name.value.length === 0) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserName', '')
      }
    },

    validateUserNameOnKeyup () {
      if (this.notifyAboutProductAvailability.user.name.error === '') {
        return
      }

      this.validateUserName()
    },

    validateUserName () {
      if (this.notifyAboutProductAvailability.user.name.value.length >= 3) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserName', '')
      } else if (this.notifyAboutProductAvailability.user.name.value.length >= 1 && this.notifyAboutProductAvailability.user.name.value.length < 3) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserName', this.$t('pages.checkout.user.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserName', this.$t('pages.checkout.user.name.errors.required'))
      }
    },

    validateUserEmailOnKeyup () {
      if (this.notifyAboutProductAvailability.user.email.value.length === 0) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserEmail', '')
      }

      if (this.notifyAboutProductAvailability.user.email.error === '') {
        return
      }

      this.validateUserEmail()
    },

    validateUserEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.notifyAboutProductAvailability.user.email.value) || this.notifyAboutProductAvailability.user.email.value === '') {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserEmail', '')
      } else {
        this.$store.dispatch('modal/notifyAboutProductAvailabilityValidateUserEmail', this.$t('pages.checkout.user.email.errors.invalid'))
      }
    },

    onUserPhoneInput (number, phone) {
      this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserPhone', phone.number)

      if (typeof phone.valid === 'undefined' || phone.valid === true) {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserPhoneError', '')
      } else {
        this.$store.dispatch('modal/notifyAboutProductAvailabilitySetUserPhoneError', this.$t('pages.checkout.user.phone.errors.invalid'))
      }
    },

    submit () {
      this.loading = true

      const data = {
        user: {
          name: this.notifyAboutProductAvailability.user.name.value,
          lastname: this.notifyAboutProductAvailability.user.lastname.value,
          patronymic: this.notifyAboutProductAvailability.user.patronymic.value,
          phone: this.notifyAboutProductAvailability.user.phone.number,
          email: this.notifyAboutProductAvailability.user.email.value
        },
        callback: this.notifyAboutProductAvailability.callback,
        note: this.notifyAboutProductAvailability.note.body
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/products/${this.notifyAboutProductAvailability.product.id}/notifications/available`, data)
        .then((res) => {
          this.notification.message = this.$t('product.notification.available.success.message')
          this.notification.class = 'is-success'
          this.notification.active = true
        })
        .catch((error) => {
          this.notification.message = error
          this.notification.class = 'is-danger'
          this.notification.active = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
