
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

import Callcenter from '@/components/Callcenter'
import MenusHeaderMenu from '@/components/menus/HeaderMenu'
// import Location from '@/components/Location'
import Logo from '@/components/Logo'

export default {
  components: {
    Callcenter,
    MenusHeaderMenu,
    // Location,
    Logo
  },

  mixins: [
    clickaway
  ],

  computed: {
    ...mapGetters({
      location: 'location',
      callcenter: 'callcenter'
    })
  }
}
