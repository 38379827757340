
import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: Boolean,
      default () {
        return false
      },
      required: true
    }
  },

  data: () => ({
    maxDefault: 14, // Max menu items
    isActive: false
  }),

  computed: {
    ...mapGetters({
      categories: 'categories',
      settings: 'settings'
    }),

    max () {
      return this.maxDefault > this.categories[0].children.length ? this.categories[0].children.length : this.maxDefault
    },

    isIndex () {
      return [this.localePath('index'), `${this.localePath('index')}/`].includes(this.$route.path)
    }
  },

  watch: {
    active: {
      handler (val) {
        this.isActive = val
      },
      immediate: true
    }
  },

  methods: {
    generateLink (slug) {
      if (slug.includes('/')) {
        const slugs = slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug } })
      }
    },

    mouseoverCategory (ref) {
      if (this.$refs[ref] && this.$refs[ref].length) {
        this.$refs[ref][0].classList.add('is-block')
      }
    },

    mouseleaveCategory (ref) {
      setTimeout(() => {
        if (this.$refs[ref] && this.$refs[ref].length) {
          this.$refs[ref][0].classList.remove('is-block')
        }
      }, 300)
    },

    mouseoverChildCategory (ref) {
      if (this.$refs[ref] && this.$refs[ref].length) {
        this.$refs[ref][0].classList.remove('is-hidden')
      }
    },

    mouseleaveChildCategory (ref) {
      setTimeout(() => {
        if (this.$refs[ref] && this.$refs[ref].length) {
          this.$refs[ref][0].classList.add('is-hidden')
        }
      }, 300)
    },

    toggleIsActive () {
      this.isActive = this.isIndex && this.settings.index.show_catalog ? this.isActive : !this.isActive
    }
  }
}
