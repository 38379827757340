
export default {
  props: {
    faqs: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    title: {
      type: String,
      default () {
        return this.$t('faq.title')
      },
      required: false
    }
  },

  methods: {
    toggleAccordion (event) {
      const matches = document.querySelectorAll('.accordion-item-head')
      const isActive = event.target.classList.contains('active')

      for (let i = 0; i < matches.length; i++) {
        matches[i].classList.remove('active')
      }

      if (!isActive) {
        event.target.classList.add('active')
      }
    }
  }
}
