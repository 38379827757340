
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

import ProductPartialsStore from '@/components/product/partials/Store'

export default {
  components: {
    ProductPartialsStore,
    LazyModalsLocationModal: defineAsyncComponent(() => import('@/components/modals/LocationModal'))
  },

  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      locationModalActive: false,
      stores: [],
      page: 0,
      pages: undefined,
      reachedLastPage: false,
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      country: 'country',
      currency: 'currency',
      location: 'location'
    }),

    oldPrice () {
      if (this.product.price.old) {
        return this.product.price.old + this.addOnPrice
      }

      return 0
    },

    price () {
      return this.product.price.current + this.addOnPrice
    }
  },

  async created () {
    if (process.client && this.product.stores_count > 1) {
      await this.getStores()
    }
  },

  methods: {
    async getStores () {
      if (typeof this.pages !== 'undefined' && this.page >= this.pages) {
        return
      }

      this.page += 1
      this.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/product/${this.product.id}/stores`, { progress: false })
        .then((res) => {
          this.pages = res.meta.last_page
          this.reachedLastPage = this.page === this.pages
          this.stores = [...this.stores, ...res.data]
        })
        .finally(() => {
          this.loading = false
        })
    },

    toggleLocationModal () {
      this.locationModalActive = !this.locationModalActive
    }
  }
}
