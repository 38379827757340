
import { mapGetters } from 'vuex'

import AuthLogin from '@/components/auth/Login'

export default {
  components: {
    AuthLogin
  },

  middleware: ['auth', 'guest'],
  auth: 'guest',

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('auth.login.title'),
          to: '#',
          active: true
        }
      ],
      notification: {
        active: false,
        message: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      logo: 'logo'
    })
  },

  mounted () {
    if (this.$route.query.error === 'failed') {
      this.notification.message = this.$t('auth.social.errors.failed')
      this.notification.active = true
    }
  },

  head () {
    return {
      title: this.$t('auth.login.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('auth.login.title')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('auth.login.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('auth.login.title')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
