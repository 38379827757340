
import { mapGetters } from 'vuex'

export default {
  data () {
    return {

    }
  },

  computed: {
    ...mapGetters({

    })
  },

  methods: {

  }
}
