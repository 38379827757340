
import { defineAsyncComponent } from '@vue/composition-api'

export default {
  components: {
    LazyModalsGalleryModal: defineAsyncComponent(() => import('@/components/modals/GalleryModal'))
  },

  props: {
    product: {
      required: true,
      type: Object
    },
    images: {
      required: true,
      type: Array
    },
    videos: {
      required: true,
      type: [Object, Array]
    }
  },

  data () {
    return {
      isVisible: false,
      galleryModalActive: false,
      hover: false
    }
  },

  computed: {
    slides () {
      let slides = []

      if (this.videos && typeof this.videos === 'object' && 'storage' in this.videos && this.videos.storage.length) {
        slides = slides.concat(
          this.videos.storage.map(video => Object.assign({}, { video, type: 'video' }))
        )
      }

      if (this.images && this.images.length > 0) {
        slides = slides.concat(
          this.images.map(image => Object.assign({}, { image, type: 'image' }))
        )
      }

      if (this.videos && typeof this.videos === 'object' && this.videos.length > 0) {
        if ('youtube' in this.videos && this.videos.youtube.length) {
          slides = slides.concat(
            this.videos.youtube.map(video => Object.assign({}, { video, type: 'iframe' }))
          )
        }

        if ('vimeo' in this.videos && this.videos.vimeo.length) {
          slides = slides.concat(
            this.videos.vimeo.map(video => Object.assign({}, { video, type: 'iframe' }))
          )
        }
      }

      return slides
    }
  },

  methods: {
    toggleGalleryModal () {
      this.galleryModalActive = !this.galleryModalActive

      const parent = document.getElementById('productDescriptionStickyWithModal')

      if (parent) {
        parent.style.zIndex = this.galleryModalActive ? '100000' : '1'
      }
    }
  }
}
