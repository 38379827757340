
import { mapGetters } from 'vuex'

import operating from '@/mixins/callcenter/operating.js'

export default {
  mixins: [
    operating
  ],

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      callback: 'modal/callback'
    })
  }
}
