
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    },
    filters: {
      required: true,
      default () {
        return []
      },
      type: Array
    }
  },

  data () {
    return {
      active: false,
      sort: this.value
    }
  },

  watch: {
    value: {
      handler (val) {
        this.sort = val
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    hideSortOptions () {
      this.active = false
    },

    changeSort (by) {
      this.sort.by = by

      const index = this.filters.findIndex(f => f.key === this.sort.filterKey)

      const filter = {
        key: this.sort.filterKey,
        values: [
          this.sort.by.value
        ]
      }

      const defaultSortKey = Object.keys(this.sort.options)[0]

      if (index !== -1 && this.sort.by.value !== defaultSortKey) {
        this.filters[index] = filter
      } else if (index === -1 && this.sort.by.value !== defaultSortKey) {
        this.filters.push(filter)
      } else if (index !== -1 && this.sort.by.value === defaultSortKey) {
        this.filters.splice(index, 1)
      }

      this.$emit('sorted', this.filters)
    }
  }
}
