
import { defineAsyncComponent } from '@vue/composition-api'

import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

import Magnifier from '@/components/Magnifier'

Vue.use(VueObserveVisibility)

if (process.client) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
  Vue.use(VueAwesomeSwiper)
}

export default {
  components: {
    Magnifier,
    LazyModalsGalleryModal: defineAsyncComponent(() => import('@/components/modals/GalleryModal'))
  },

  props: {
    product: {
      required: true,
      type: Object
    },
    images: {
      required: true,
      type: Array
    },
    videos: {
      required: true,
      type: [Object, Array]
    }
  },

  data () {
    return {
      isVisible: false,
      thumbnail: {
        offset: 0,
        perView: 4,
        slice: {
          start: 0,
          end: 4
        }
      },
      isMounted: false,
      galleryModalActive: false,
      currentSlideIndex: 0,
      hover: false,
      imagesSwiperOptions: {
        slidesPerView: 1,
        // simulateTouch: false,
        spaceBetween: 16,
        // loop: true,
        // loopedSlides: 5, // looped slides should be the same
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        // allowTouchMove: false,
        on: {
          slideChange: this.topSlideChange
        }
        // breakpoints: {
        //   768: {
        //     effect: 'slide'
        //   }
        // }
      }
    }
  },

  computed: {
    thumbs () {
      return this.slides.slice(this.thumbnail.slice.start, this.thumbnail.slice.end).map(t => `${t.type}-${t[t.type].id}`)
    },

    slides () {
      let slides = []

      if (this.videos && typeof this.videos === 'object' && 'storage' in this.videos && this.videos.storage.length) {
        slides = slides.concat(
          this.videos.storage.map(video => Object.assign({}, { video, type: 'video' }))
        )
      }

      if (this.images && this.images.length > 0) {
        slides = slides.concat(
          this.images.map(image => Object.assign({}, { image, type: 'image' }))
        )
      }

      if (this.videos && typeof this.videos === 'object' && this.videos.length > 0) {
        if ('youtube' in this.videos && this.videos.youtube.length) {
          slides = slides.concat(
            this.videos.youtube.map(video => Object.assign({}, { video, type: 'iframe' }))
          )
        }

        if ('vimeo' in this.videos && this.videos.vimeo.length) {
          slides = slides.concat(
            this.videos.vimeo.map(video => Object.assign({}, { video, type: 'iframe' }))
          )
        }
      }

      return slides
    }
  },

  created () {
    if ((typeof this.$route.query === 'object' &&
      'tab' in this.$route.query &&
      this.$route.query.tab === 'description') ||
      !('tab' in this.$route.query)
    ) {
      this.isVisible = true
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
    })
  },

  methods: {
    slideTo (index) {
      this.imagesSwiper.slideTo(index, 1000, false)
      if ((index - this.thumbnail.offset) === 0 && this.thumbnail.offset > 0) {
        this.thumbnail.offset--
        this.thumbnail.slice.start--
        this.thumbnail.slice.end--
      } else if ((index - this.thumbnail.offset) === (this.thumbnail.perView - 1) && (this.images.length - this.thumbnail.offset - this.thumbnail.slice.end) > 0) {
        this.thumbnail.offset++
        this.thumbnail.slice.start++
        this.thumbnail.slice.end++
      }
    },

    toggleThumb (index) {
      this.$refs.thumbsSwiper.querySelectorAll('div').forEach(
        function (slide) {
          slide.classList.remove('swiper-slide-active')
        }
      )

      this.$refs.thumbsSwiper.querySelector(`:nth-child(${index + 1})`).classList.add('swiper-slide-active')
    },

    topSlideChange () {
      const slidesLength = this.imagesSwiper.slides.length
      const lastVisibleIndex = this.imagesSwiper.realIndex + this.imagesSwiper.params.slidesPerView

      // if swiper reaches the end of displayed items, goToNext redirects swiper to the start
      // if (lastVisibleIndex > slidesLength) {
      //   this.imagesSwiper.slideTo(1)
      // }

      // if swiper wants to go before the first item, then forward swiper to the last item
      if (lastVisibleIndex >= slidesLength) {
        this.imagesSwiper.slideTo((slidesLength - this.imagesSwiper.params.slidesPerView) + 1)
      }

      this.currentSlideIndex = this.imagesSwiper.realIndex

      this.toggleThumb(this.imagesSwiper.realIndex)
    },

    toggleMagnifier (hover) {
      if (this.slides[this.imagesSwiper.realIndex] && this.slides[this.imagesSwiper.realIndex].type === 'image') {
        this.hover = hover
      }
    },

    toggleGalleryModal () {
      this.galleryModalActive = !this.galleryModalActive

      const parent = document.getElementById('productDescriptionStickyWithModal')

      if (parent) {
        parent.style.zIndex = this.galleryModalActive ? '100000' : '1'
      }
    },

    visibilityChanged (isVisible, entry) {
      if (!this.isVisible && isVisible) {
        this.isVisible = isVisible
        this.$refs.imagesSwiper.swiper.update()
      }
    }
  }
}
