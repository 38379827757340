import { render, staticRenderFns } from "./checkout.vue?vue&type=template&id=b76ecae2&"
import script from "./checkout.vue?vue&type=script&lang=js&"
export * from "./checkout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormsPaymentForm: require('/app/components/forms/PaymentForm.vue').default,Checkoutable: require('/app/components/Checkoutable.vue').default})
