
import { mapGetters } from 'vuex'

import filters from '@/mixins/filters.js'
import gtm from '@/mixins/gtm.js'

import ProductCard from '@/components/product/Card'
import FiltersPrice from '@/components/filters/Price'
import FiltersTouchscreenButtons from '@/components/filters/TouchscreenButtons'
import FiltersSortDropdown from '@/components/filters/SortDropdown'
import FiltersChosen from '@/components/filters/Chosen'
import FiltersBadges from '@/components/filters/Badges'
import FiltersStockStatus from '@/components/filters/StockStatus'
import FiltersConditionStatus from '@/components/filters/ConditionStatus'
import LoadMore from '@/components/LoadMore'
// import Pagination from '@/components/Pagination'
import Faq from '@/components/Faq'

export default {
  components: {
    ProductCard,
    FiltersPrice,
    FiltersTouchscreenButtons,
    FiltersSortDropdown,
    FiltersChosen,
    FiltersBadges,
    FiltersStockStatus,
    FiltersConditionStatus,
    LoadMore,
    // Pagination,
    Faq
  },

  mixins: [
    filters,
    gtm
  ],

  props: {
    category: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      path: {
        name: 'slug',
        params: {
          slug: this.$route.params.slug
        }
      },
      pathWithParams: {
        name: 'slug-params',
        params: {
          slug: this.$route.params.slug,
          params: undefined
        }
      },
      totalFilterRoles: [
        'series'
      ]
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    }),

    breadcrumbs () {
      const length = this.category.breadcrumbs.length

      this.category.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = this.chosenFilters.length > 0 ? false : length === (index + 1)
      })

      const breadcrumbs = [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.category.breadcrumbs)

      this.chosenFilters.forEach((chosenFilter) => {
        if ([this.conditionStatusesFilterKey, this.stockStatusesFilterKey, this.badgesFilterKey, this.priceFilterKey, this.sort.filterKey].includes(chosenFilter.key)) {
          return
        }

        chosenFilter.values.forEach((value) => {
          const path = this.localePath({
            name: 'slug-params',
            params: {
              slug: this.category.page.slug.split('/')[0],
              params: `${chosenFilter.slug}--${value.slug}`
            }
          })

          breadcrumbs.push({
            title: value.title,
            to: path,
            active: this.$route.path === path
          })
        })
      })

      return breadcrumbs
    },

    client () {
      return process.client
    }
  },

  created () {
    this.pushGtmViewItemListEvent(this.category.products, this.category.title, this.category.id)

    if (this.$route.params.params) {
      this.params = this.$route.params.params

      this.params.split('_').forEach((param) => {
        const values = param.split('--')
        const filterSlug = values.shift()

        this.initializeFiltersChosen(filterSlug, values, this.category.filters)
        this.initializeConditionStatusChosen(filterSlug, values, this.category.statuses.condition)
        this.initializeStockStatusChosen(filterSlug, values, this.category.statuses.stock)
        this.initializeBadgesChosen(filterSlug, values, this.category.badges)
        this.initializePricesChosen(filterSlug, values)
        this.initializeSortChosen(filterSlug, values)
      })
    }

    this.initializeSortDefault()

    this.setInitialPage(this.category.pages)
  },

  methods: {
    calculateFilterHeight (filter) {
      if (this.$refs[`filter[${filter.id}]`] === undefined || this.$refs[`filter[${filter.id}]`][0] === undefined) {
        return false
      }

      const children = [...this.$refs[`filter[${filter.id}]`][0].children]
      let height = 0

      children.splice(0, filter.max_filter_values).forEach((filter) => {
        height += filter.clientHeight
      })

      return `${height}px`
    },

    getFilterCount (filter, value) {
      const chosenFilterValue = this.chosenFilters.findIndex(cf => cf.key === filter.slug && cf.values.findIndex(v => v.slug === value.slug) >= 0) >= 0
      const totalFilterRole = this.totalFilterRoles.includes(filter.role)

      if (this.chosenFilters.length && chosenFilterValue && totalFilterRole && value.total > 0) {
        return value.total
      } else if (this.chosenFilters.length && !chosenFilterValue && totalFilterRole && value.total > 0) {
        return `+${value.total}`
      } else {
        return value.count
      }
    },

    clickOnFilter (event, characteristic, value) {
      this.checkFilterOnLinkClick(event)

      this.category.filters.find(filter => filter.id === characteristic.id).values.find(v => v.slug === value.slug).active = !value.active
    },

    async loadMoreProducts () {
      if (this.page >= this.category.pages) {
        return
      }

      this.page += 1
      this.loading = true

      const category = `category--${this.$route.params.slug}${this.params.length ? '_' : ''}`

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/products/${category}${this.params}?page=${this.page}`)
        .then((res) => {
          this.reachedLastPage = this.page === this.category.pages
          this.category.products = [...this.category.products, ...res.data]

          this.pushGtmViewItemListEvent(res.data, this.category.title, this.category.id)

          // history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?page=${this.page}`)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getPath (params) {
      if (params) {
        return this.localePath({
          name: 'slug-params',
          params: {
            slug: this.$route.params.slug,
            params
          }
        })
      } else {
        return this.localePath({
          name: 'slug',
          params: {
            slug: this.$route.params.slug
          }
        })
      }
    }
  },

  head () {
    const head = {}

    if (this.category.products.length > 0) {
      const product = this.category.products[0]

      head.meta = [
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${product.images[0].folder}/page/lazy/${product.images[0].url}`
        }
      ]

      // head.link = []

      // this.category.products.slice(0, 6).forEach((product) => {
      //   head.link.push({
      //     rel: 'prefetch',
      //     // crossorigin: 'anonymous',
      //     href: `${product.images[0].folder}/page/lazy/${product.images[0].url}`
      //   })
      // })
    }

    return head
  }
}
