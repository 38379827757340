
import { mapGetters } from 'vuex'

import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min'

export default {
  components: {
    VueSlider
  },

  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    },
    filters: {
      required: true,
      default () {
        return []
      },
      type: Array
    }
  },

  data () {
    return {
      priceInputChanged: false,
      priceFilterKey: 'price',
      prices: this.value
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency'
    })
  },

  watch: {
    value: {
      handler (val) {
        this.prices = val
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    priceInputKeydown (e) {
      if (e.keyCode === 13/* enter key */) {
        this.applyPriceInput()
      } else if ([8, 38, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(e.keyCode)) {
        /* numbers, arrows, del, backspace keys */
        this.priceInputChanged = true
      }
    },

    applyPriceInput () {
      this.priceInputChanged = false

      this.prices.range[0] = this.$refs['min-price-input'].value
      this.prices.range[1] = this.$refs['max-price-input'].value

      const range = [
        parseInt(this.prices.range[0]),
        parseInt(this.prices.range[1])
      ]

      if (range[0] < this.prices.min) {
        range[0] = this.prices.min
      }

      if (range[0] > this.prices.max) {
        range[0] = this.prices.max
      }

      if (range[1] > this.prices.max) {
        range[1] = this.prices.max
      }

      if (range[0] > range[1]) {
        range[1] = range[0]
      }

      this.prices.range = range

      this.$refs['min-price-input'].value = this.prices.range[0]
      this.$refs['max-price-input'].value = this.prices.range[1]

      this.changePriceRange()
    },

    changePriceRange () {
      const index = this.filters.findIndex(f => f.key === this.priceFilterKey)

      const filter = {
        key: this.priceFilterKey,
        values: [
          parseInt(this.prices.range[0]),
          parseInt(this.prices.range[1])
        ]
      }

      if (index !== -1) {
        this.filters[index] = filter
      } else {
        this.filters.push(filter)
      }

      this.$emit('priceFilterApplied', this.filters)
    }
  }
}
