
export default {
  props: {
    product: {
      required: true,
      type: Object
    },
    // Image
    image: {
      type: Object,
      default () {
        return {
          src: '',
          lazy: '',
          alt: ''
        }
      }
    },
    // configuration item
    configs: {
      type: Object,
      default () {
        return {
          width: 420, // enlarge the area
          height: 420, // enlarge the area
          mask: {
            width: 210,
            height: 210
          },
          scale: 2 // scale up
        }
      }
    }
  },

  data () {
    return {
      imgObj: {},
      moveLeft: 0,
      moveTop: 0,
      transformMask: 'translate(0px, 0px)',
      showMagnifier: false,
      showMask: false,
      init: false,
      isMounted: false
    }
  },

  computed: {
    lazyWidth () {
      return this.configs.scale * this.configs.width
    },

    lazyHeight () {
      return this.configs.scale * this.configs.height
    },

    available () {
      return ['available', 'awaiting', 'preorder', 'produce'].includes(this.product.store.stockStatus.type)
    },

    stickers () {
      return this.product.stickers.slice(0, 4)
    }
  },

  mounted () {
    this.configs.width = this.$refs.magnifier.clientHeight
    this.configs.height = this.$refs.magnifier.clientHeight
    this.configs.mask.width = this.configs.width / 2
    this.configs.mask.height = this.configs.height / 2

    this.$nextTick(() => {
      this.isMounted = true
    })
  },

  methods: {
    handMove (e) {
      if (!this.isMounted ||
        (this.imgObj && Object.keys(this.imgObj).length === 0 && this.imgObj.constructor === Object)
      ) {
        return
      }

      if (!this.showMagnifier || !this.showMask || !this.init) {
        this.handOver()
      }

      // Dynamically obtain the location of small graph (or monitor scroll)
      const imgRectNow = this.imgObj.getBoundingClientRect()
      const objX = e.clientX - imgRectNow.left
      const objY = e.clientY - imgRectNow.top

      // Calculate the coordinates of the upper left corner of the initial mask
      let maskX = objX - this.configs.mask.width / 2
      let maskY = objY - this.configs.mask.height / 2

      // Judge whether the limit is exceeded and correct
      maskY = maskY < 0 ? 0 : maskY
      maskX = maskX < 0 ? 0 : maskX

      if (maskY + this.configs.mask.height >= imgRectNow.height) {
        maskY = imgRectNow.height - this.configs.mask.height
      }

      if (maskX + this.configs.mask.width >= imgRectNow.width) {
        maskX = imgRectNow.width - this.configs.mask.width
      }

      // Mask move
      this.transformMask = `translate(${maskX}px, ${maskY}px)`

      // Background image move
      this.moveLeft = -maskX * this.configs.scale + 'px'
      this.moveTop = -maskY * this.configs.scale + 'px'
    },

    handOut () {
      if (!this.isMounted) {
        return
      }

      this.showMagnifier = false
      this.showMask = false
      this.init = false
    },

    handOver () {
      if (!this.isMounted) {
        return
      }

      if (!this.init) {
        this.init = true

        this.imgObj = this.$refs[`image[${this.image.id}]`].$el
      }

      this.showMagnifier = true
      this.showMask = true
    }
  }
}
