import { render, staticRenderFns } from "./thanks.vue?vue&type=template&id=2adee431&"
import script from "./thanks.vue?vue&type=script&lang=js&"
export * from "./thanks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/app/components/Breadcrumbs.vue').default,OrderDelivery: require('/app/components/order/Delivery.vue').default,OrderPayment: require('/app/components/order/Payment.vue').default,OrderInstallment: require('/app/components/order/Installment.vue').default})
