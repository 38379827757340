
import { mapGetters } from 'vuex'

import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown)

export default {
  props: {
    slide: {
      required: true,
      default () {
        return []
      },
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      currency: 'currency'
    }),

    link () {
      if (this.slide.bannerable.page.slug.includes('/')) {
        const slugs = this.slide.bannerable.page.slug.split('/')

        return this.localePath({
          name: 'slug-params',
          params: {
            slug: slugs[0],
            params: slugs[1]
          }
        })
      } else {
        return this.localePath({ name: 'slug', params: { slug: this.slide.bannerable.page.slug } })
      }
    },

    background () {
      if (this.$mq === 'fullhd' && this.$mq in this.slide.backgrounds) {
        return this.slide.backgrounds.fullhd
      } else if (this.$mq === 'widescreen' && this.$mq in this.slide.backgrounds) {
        return this.slide.backgrounds.widescreen
      } else if (this.$mq === 'desktop' && this.$mq in this.slide.backgrounds) {
        return this.slide.backgrounds.desktop
      } else if (this.$mq === 'tablet' && this.$mq in this.slide.backgrounds) {
        return this.slide.backgrounds.tablet
      } else if (this.$mq === 'mobile' && this.$mq in this.slide.backgrounds) {
        return this.slide.backgrounds.mobile
      } else {
        return this.slide.backgrounds.mobileSmall
      }
    },

    style () {
      return {
        '--mobile-small': this.slide.backgrounds.mobileSmall.style.background,
        '--mobile': this.slide.backgrounds.mobile.style.background,
        '--tablet': this.slide.backgrounds.tablet.style.background,
        '--desktop': this.slide.backgrounds.desktop.style.background,
        '--widescreen': this.slide.backgrounds.widescreen.style.background,
        '--fullhd': this.slide.backgrounds.fullhd.style.background
      }
    }
  },

  methods: {
    setOpacity (color, opacity) {
      if (color === 'transparent') {
        return 'transparent'
      } else if (color.startsWith('#')) {
        let hex = color.replace('#', '')

        if (hex.length === 3) {
          hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
        }

        const r = parseInt(hex.substring(0, 2), 16)
        const g = parseInt(hex.substring(2, 4), 16)
        const b = parseInt(hex.substring(4, 6), 16)

        return `rgba(${r},${g},${b},${opacity})`
      } else if (color.startsWith('rgba')) {
        const rgba = color.substring(5, color.length - 1).replace(/ /g, '').split(',')

        return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${opacity})`
      } else if (color.startsWith('rgb')) {
        const rgb = color.substring(4, color.length - 1).replace(/ /g, '').split(',')

        return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`
      } else if (color.startsWith('hsla')) {
        const hsla = color.substring(4, color.length - 1).replace(/ /g, '').split(',')

        return `hsla(${hsla[0]},${hsla[1]},${hsla[2]},${opacity})`
      } else if (color.startsWith('hsl')) {
        const hsl = color.substring(4, color.length - 1).replace(/ /g, '').split(',')

        return `hsla(${hsl[0]},${hsl[1]},${hsl[2]},${opacity})`
      } else {
        return 'transparent'
      }
    }
  }
}
