
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'
import { mixin as clickaway } from 'vue-clickaway'

import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'

import location from '@/mixins/location.js'

import ProductPartialsCheaperTogether from '@/components/product/partials/CheaperTogether'
import ProductPartialsCompare from '@/components/product/partials/Compare'
import ProductPartialsImages from '@/components/product/partials/Images'
import ProductPartialsWishlist from '@/components/product/partials/Wishlist'
import StorePartialsRating from '@/components/store/partials/Rating'
import ProductPartialsAlternatives from '@/components/product/partials/Alternatives'

Vue.use(vueAwesomeCountdown)

export default {
  components: {
    ProductPartialsCheaperTogether,
    ProductPartialsCompare,
    ProductPartialsImages,
    ProductPartialsWishlist,
    StorePartialsRating,
    ProductPartialsAlternatives,
    LazyModalsLocationModal: defineAsyncComponent(() => import('@/components/modals/LocationModal'))
  },

  mixins: [
    location,
    clickaway
  ],

  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    locationModalActive: false,
    delivery: {
      active: true
    },
    performance: {
      tests: {
        active: false
      }
    },
    warranty: {
      types: []
    },
    addon: {
      types: []
    }
  }),

  computed: {
    ...mapGetters({
      settings: 'settings',
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      country: 'country',
      currency: 'currency',
      localDeliveryOptions: 'localDeliveryOptions',
      localPaymentOptions: 'localPaymentOptions',
      location: 'location'
    }),

    paymentOptions () {
      return Object.keys(this.localPaymentOptions).length
        ? JSON.parse(JSON.stringify(this.localPaymentOptions[this.product.store.store_id]))
        : []
    },

    stocks () {
      return this.product.stocks.reduce((quantity, item) => quantity + item.quantity, 0)
    },

    link () {
      return {
        name: 'slug',
        params: {
          slug: this.product.page.slug
        }
      }
    },

    installmentPerMonthFromPrice () {
      let perMonth = 0

      for (let i = 0; i < this.product.creditProducts.length; i++) {
        let commission = 0

        if (this.product.creditProducts[i] && !this.product.creditProducts[i].included) {
          if (this.product.creditProducts[i].calculation === 'simple') {
            commission = Math.ceil(this.price / 100 * this.product.creditProducts[i].terms[0].percent)
          } else if (this.product.creditProducts[i].calculation === 'complex') {
            commission = Math.ceil((this.price / (1 - this.product.creditProducts[i].terms[0].percent / 100)) - this.price)
          } else if (this.product.creditProducts[i].calculation === 'monthly') {
            commission = Math.ceil(this.price / 100 * this.product.creditProducts[i].terms[0].percent * this.product.creditProducts[i].terms[0].payments)
          }
        }

        const thisPerMonth = Math.ceil((this.price + commission) / this.product.creditProducts[i].terms[0].payments)

        if (i === 0 || perMonth > thisPerMonth) {
          perMonth = thisPerMonth
        }
      }

      return perMonth
    },

    addOnPrice () {
      let addonsPrice = 0
      let warrantiesPrice = 0

      this.addon.types.forEach((type) => {
        addonsPrice += (Object.keys(type.selected).length ? type.selected.price : 0)
      })

      this.warranty.types.forEach((type) => {
        warrantiesPrice += (Object.keys(type.selected).length ? type.selected.price : 0)
      })

      return warrantiesPrice + addonsPrice
    },

    oldPrice () {
      if (this.product.store.price.old) {
        return this.product.store.price.old + this.addOnPrice
      }

      return 0
    },

    price () {
      return this.product.stores_count === 1
        ? this.product.store.price.current + this.addOnPrice
        : this.product.price.min
    },

    splitPrice () {
      return this.price.toString().split('.')
    },

    discountPercent () {
      return this.oldPrice ? Math.ceil((this.oldPrice - this.price) / this.oldPrice * 100) : 0
    },

    discount () {
      return this.oldPrice ? this.oldPrice - this.price : 0
    },

    topCharacteristics () {
      return this.product.characteristics.map(group => group.characteristics).flat().filter(c => c.top)
    },

    flatCharacteristics () {
      return this.product.characteristics.map(group => group.characteristics).flat()
    }
  },

  watch: {
    'location.city.id': {
      async handler (val) {
        if (val !== null && Number.isInteger(val)) {
          await this.getDeliveryOptions(this.product, this.location.city)
        }
      },
      immediate: true,
      deep: true
    }
  },

  created () {
    this.product.store.warranty_types.forEach((type) => {
      const warrantyTypeIndex = this.warranty.types.push({
        id: type.id,
        without: type.without,
        title: type.title,
        description: type.description,
        isActive: false,
        selected: {},
        warranties: JSON.parse(JSON.stringify(type.warranties))
      })

      const warrantyType = this.warranty.types[warrantyTypeIndex - 1]
      const defaultWarranty = warrantyType.warranties.find(warranty => warranty.default === true)

      if (defaultWarranty) {
        this.changeWarranty(defaultWarranty, warrantyType.id)
      }
    })

    this.product.store.addon_types.forEach((type) => {
      const addonTypeIndex = this.addon.types.push({
        id: type.id,
        without: type.without,
        title: type.title,
        description: type.description,
        isActive: false,
        selected: {},
        addons: JSON.parse(JSON.stringify(type.addons))
      })

      const addonType = this.addon.types[addonTypeIndex - 1]
      const defaultAddon = addonType.addons.find(addon => addon.default === true)

      if (defaultAddon) {
        this.changeAddon(defaultAddon, addonType.id)
      }
    })
  },

  methods: {
    openBadgeInfoModal (badge) {
      this.$store.dispatch('modal/setInfoModalTitle', badge.title)
      this.$store.dispatch('modal/setInfoModalDescription', badge.description)
      this.$store.dispatch('modal/setInfoModalPage', badge.page)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    openWarrantyTypeInfoModal (warrantyType) {
      this.$store.dispatch('modal/setInfoModalTitle', warrantyType.title)
      this.$store.dispatch('modal/setInfoModalDescription', warrantyType.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    openAddonTypeInfoModal (addonType) {
      this.$store.dispatch('modal/setInfoModalTitle', addonType.title)
      this.$store.dispatch('modal/setInfoModalDescription', addonType.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    openCreditProductInfoModal (creditProduct) {
      this.$store.dispatch('modal/setInfoModalTitle', creditProduct.title)
      this.$store.dispatch('modal/setInfoModalDescription', creditProduct.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    bestCreditProductTermOfSelected (paymentCompany) {
      return paymentCompany.terms[paymentCompany.terms.length - 1]
    },

    toggleLocationModal () {
      this.locationModalActive = !this.locationModalActive
    },

    toggleWarrantyTypeDropdown (warrantyTypeId) {
      if (this.addon.types.length) {
        this.hideAddonDropDown()
      }

      const otherWarranties = this.warranty.types.find(type => type.id !== warrantyTypeId)

      if (otherWarranties) {
        otherWarranties.isActive = false
      }

      this.warranty.types.find(type => type.id === warrantyTypeId).isActive = !this.warranty.types.find(type => type.id === warrantyTypeId).isActive
    },

    hideWarrantyDropDown () {
      this.warranty.types.forEach((type) => {
        type.isActive = false
      })
    },

    changeWarranty (warranty, warrantyTypeId) {
      this.hideWarrantyDropDown()

      this.warranty.types.find(type => type.id === warrantyTypeId).selected = warranty

      if (warranty === false) {
        this.warranty.types.find(type => type.id === warrantyTypeId).warranties.shift()
      }

      if (this.warranty.types.find(type => type.id === warrantyTypeId).warranties[0] && warranty) {
        this.warranty.types.find(type => type.id === warrantyTypeId).warranties.unshift(false)
      }

      this.$emit('warrantySelected', this.warranty.types.filter(c => c.selected && Object.keys(c.selected).length !== 0 && c.selected.constructor === Object).map(c => c.selected))
    },

    toggleAddonTypeDropdown (addonTypeId) {
      if (this.warranty.types.length) {
        this.hideWarrantyDropDown()
      }

      const otherAddons = this.addon.types.find(type => type.id !== addonTypeId)

      if (otherAddons) {
        otherAddons.isActive = false
      }

      this.addon.types.find(type => type.id === addonTypeId).isActive = !this.addon.types.find(type => type.id === addonTypeId).isActive
    },

    hideAddonDropDown () {
      this.addon.types.forEach((type) => {
        type.isActive = false
      })
    },

    changeAddon (addon, addonTypeId) {
      this.hideAddonDropDown()

      this.addon.types.find(type => type.id === addonTypeId).selected = addon

      if (addon === false) {
        this.addon.types.find(type => type.id === addonTypeId).addons.shift()
      }

      if (this.addon.types.find(type => type.id === addonTypeId).addons[0] && addon) {
        this.addon.types.find(type => type.id === addonTypeId).addons.unshift(false)
      }

      this.$emit('addonSelected', this.addon.types.filter(c => c.selected && Object.keys(c.selected).length !== 0 && c.selected.constructor === Object).map(c => c.selected))
    },

    addToCart (item, itemStore, warranties, addons) {
      this.$store.dispatch('cart/setCartType', 'cart')
      this.$store.dispatch('cart/addToCart', { item, itemStore, warranties, addons })
      this.$store.dispatch('cart/setCartModalActive', !this.cartModal.active)
    },

    addToInstallmentCart (item, itemStore, warranties, addons) {
      this.$store.dispatch('cart/setCartType', 'installment')
      this.$store.dispatch('cart/addToCart', { item, itemStore, warranties, addons })

      this.$router.push(this.localePath({ name: 'installment' }))
    },

    togglePreorderModal () {
      this.$store.dispatch('modal/setPreorderId', this.product.id)
      this.$store.dispatch('modal/setPreorderTitle', this.product.title)
      this.$store.dispatch('modal/setPreorderImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/togglePreorderModal', true)
    },

    toggleNotifyAboutAvailabilityModal () {
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityId', this.product.id)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityTitle', this.product.title)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/toggleNotifyAboutProductAvailabilityModal', true)
    },

    togglePaymentOption (option) {
      this.$store.dispatch('modal/setInfoModalTitle', option.title)
      this.$store.dispatch('modal/setInfoModalDescription', option.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
      this.$forceUpdate()
    },

    calculateDeliveryTerms (item) {
      // Add 1 day if the current time is more than the shipment_before time
      const shipTodayAddDays = this.$moment().locale(this.$i18n.locale).format('HH:mm:ss') < item.shipment_before ? 0 : 1

      const from = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.product.store.shipment_min, `${this.product.store.shipment_min_unit.name}s`)
        .add(this.product.store.stockStatus.shipment_time_min, `${this.product.store.stockStatus.shipment_time_min_unit.name}s`)
        .add(item.delivery_time_min, `${item.delivery_time_min_unit.name}s`)

      const to = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.product.store.shipment_max, `${this.product.store.shipment_max_unit.name}s`)
        .add(this.product.store.stockStatus.shipment_time_max, `${this.product.store.stockStatus.shipment_time_max_unit.name}s`)
        .add(item.delivery_time_max, `${item.delivery_time_max_unit.name}s`)

      if (to.year() === from.year() && to.month() === from.month() && to.date() === from.date()) {
        return to.format('DD MMMM')
      } else if (to.year() === from.year() && to.month() === from.month() && to.date() !== from.date()) {
        return `${from.format('DD')} — ${to.format('DD MMMM')}`
      } else if (to.year() === from.year() && to.month() !== from.month() && to.date() !== from.date()) {
        return `${from.format('DD MMM')} — ${to.format('DD MMM')}`
      } else {
        return `${from.format('ll')} — ${to.format('ll')}`
      }
    },

    compared (data) {
      this.product.compares = data.compares
    },

    wished (data) {
      this.product.wishlists = data.wishlists
    },

    goToReviews () {
      this.$router.push(
        this.localePath({
          name: 'slug',
          params: {
            slug: this.product.store.page.slug
          },
          query: {
            tab: 'reviews'
          }
        })
      )
    }
  }
}
