
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import ProductPartialsStockStatusTag from '@/components/product/partials/StockStatusTag'

export default {
  components: {
    ProductPartialsStockStatusTag
  },

  mixins: [
    clickaway
  ],

  props: {
    hiddenMobile: {
      required: true,
      default () {
        return true
      },
      type: Boolean
    }
  },

  data: () => ({
    query: '',
    searching: false,
    timeout: null,
    timer: 500,
    results: {
      products: [],
      categories: []
    },
    minQueryLength: 3,
    dropDownIsActive: false,
    recognition: undefined,
    speaking: false,
    listening: false,
    transcription: '',
    error: ''
  }),

  computed: {
    ...mapGetters({
      currency: 'currency',
      country: 'country',
      search: 'search/search'
    }),

    input: {
      get () {
        return this.listening ? this.transcription : this.query
      },
      set (val) {
        if (this.listening) {
          this.transcription = val
        } else {
          this.query = val
        }
      }
    },

    isSearchPage () {
      // return this.$route.path === this.localePath({ name: 'search-params', params: { params: `q--${encodeURIComponent(this.query)}` } })
      return this.$route.name.split('___')[0] === 'search-params'
    }
  },

  async created () {
    if (this.isSearchPage && this.$route.params.params) {
      await this.$route.params.params.split('_').forEach((param) => {
        const values = param.split('--')

        if (values.shift() === 'q') {
          this.query = values[0]
        }
      })
    }
  },

  async mounted () {
    const search = JSON.parse(localStorage.getItem('search'))

    this.$store.dispatch('search/setSearch', { id: search ? search.id : null })
    await this.$store.dispatch('search/syncSearch')
  },

  methods: {
    validateInput () {
      return this.query.length >= this.minQueryLength
    },

    typing (e) {
      if (!this.validateInput()) {
        // this.collapseDropdown()
        return
      }

      if (e.keyCode === 13/* enter key */) {
        this.collapseDropdown()
        this.fullSearch(e)
        return
      }

      clearTimeout(this.timeout)

      if (this.isSearchPage) {
        this.dropDownIsActive = false

        return
      }

      this.timeout = setTimeout(() => {
        this.quickSearch()
      }, this.timer)
    },

    expandDropdown (e) {
      if (e) {
        e.stopPropagation()
      }

      if (this.isSearchPage) {
        this.dropDownIsActive = false

        return
      }

      if (this.validateInput() || this.search.history.length > 0 || this.search.queries.length > 0) {
        this.dropDownIsActive = true
      }
    },

    collapseDropdown () {
      this.$refs.query.blur()

      this.dropDownIsActive = false
    },

    checkCompatibility () {
      if (!this.recognition) {
        this.error = 'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
      }
    },

    standby () {
      this.recognition.stop()
      this.listening = false
      this.speaking = false
      this.quickSearch()
    },

    listen () {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
      this.recognition = SpeechRecognition ? new SpeechRecognition() : false

      if (!this.recognition) {
        this.error = 'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
        return false
      }

      this.recognition.lang = 'ru-RU'
      this.recognition.interimResults = true
      this.listening = true

      this.recognition.addEventListener('speechstart', (event) => {
        this.speaking = true
      }, { passive: true })

      this.recognition.addEventListener('speechend', (event) => {
        this.standby()
        this.$refs.query.$el.focus()
      }, { passive: true })

      this.recognition.addEventListener('result', (event) => {
        this.transcription = this.capitalizeFirstLetter(Array.from(event.results).map(result => result[0]).map(result => result.transcript).join(''))
      }, { passive: true })

      this.recognition.addEventListener('end', () => {
        this.query = this.transcription
        this.transcription = ''

        this.standby()

        if (this.listening) {
          this.recognition.start()
        }
      }, { passive: true })

      this.recognition.start()
    },

    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    clickQueryResult (query) {
      this.query = query.query

      this.quickSearch()
    },

    quickSearch (e) {
      if (this.validateInput() && this.$route.path !== this.localePath({ name: 'search-params', params: { params: `q--${encodeURIComponent(this.query)}` } })) {
        this.searching = true
        const query = this.query.trim()

        this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/search/${this.search.id}/short?q=${query}`)
          .then((res) => {
            this.results.products = res.data.products
            this.results.categories = res.data.categories

            if (res.data.query !== null) {
              this.$store.dispatch('search/addHistory', res.data.query)
            }

            this.$store.dispatch('search/setQueries', res.data.queries)

            this.searching = false

            this.expandDropdown(e)
          })
      }
    },

    fullSearch (e) {
      e.stopPropagation()

      if (this.validateInput()) {
        this.collapseDropdown()

        this.$router.replace(this.localePath({ name: 'search-params', params: { params: `q--${encodeURIComponent(this.query).replace('%2F', '%252F')}` } }))
      }
    },

    away () {
      if (this.validateInput() || this.search.history.length > 0) {
        this.collapseDropdown()
      }
    },

    close () {
      if (!['mobileSmall', 'mobile'].includes(this.$mq)) {
        this.query = ''
      }

      this.results.products = []
      this.results.categories = []

      this.$store.dispatch('search/setQueries', [])

      this.collapseDropdown()

      this.$emit('close', true)
    }
  }
}
