
import { mapGetters } from 'vuex'

import MenusCatalogMenuDropdown from '@/components/menus/CatalogMenuDropdown'

export default {
  components: {
    MenusCatalogMenuDropdown
  },

  data: () => ({
    isActive: false
  }),

  computed: {
    ...mapGetters({
      categories: 'categories',
      settings: 'settings'
    }),

    isIndex () {
      return [this.localePath('index'), `${this.localePath('index')}/`].includes(this.$route.path)
    }
  },

  watch: {
    $route () {
      this.setExpanded()
    }
  },

  created () {
    this.setExpanded()
  },

  methods: {
    setExpanded () {
      this.isActive = this.isIndex && this.settings.index.show_catalog
    },

    catalogMouseOver () {
      this.isActive = true
    },

    catalogMouseleave () {
      this.setExpanded()
    }
  }
}
