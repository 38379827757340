
import { mapGetters } from 'vuex'

import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown)

export default {
  async asyncData ({ app, route }) {
    const response = await app.$axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/order/paid/${route.query.token}`)

    return {
      order: response.data
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.payment.success.header'),
          to: '#',
          active: true
        }
      ],
      time: 3000,
      redirecting: false
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  mounted () {
    setTimeout(() => {
      this.redirect()
    }, this.time)
  },

  methods: {
    redirect () {
      this.redirecting = true
      this.$store.dispatch('order/setOrder', this.order)
      this.$router.push(this.localePath({ name: 'thanks' }))
    }
  },

  head () {
    return {
      title: this.$t('pages.payment.success.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.payment.success.description')
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex,nofollow'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
