
import { mixin as clickaway } from 'vue-clickaway'
import { defineAsyncComponent } from '@vue/composition-api'

import UserSideMenu from '@/components/user/SideMenu'

export default {
  components: {
    UserSideMenu,
    LazyModalsRemoveAddressModal: defineAsyncComponent(() => import('@/components/modals/RemoveAddressModal'))
  },

  mixins: [
    clickaway
  ],

  middleware: 'auth',

  async asyncData ({ params, $axios, app }) {
    const addresses = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/user/addresses`)

    return {
      table: {
        data: addresses.data,
        meta: {
          total: addresses.meta.total
        }
      }
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.addresses.title'),
          to: '#',
          active: true
        }
      ],
      notification: {
        active: false,
        message: '',
        class: ''
      },
      page: 1,
      itemsPerPage: 15,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      loading: false,
      sort: {
        by: 'created_at',
        order: 'desc'
      },
      table: {
        data: [],
        meta: {
          total: 0
        }
      },
      removeModal: false,
      modal: {
        active: false
      },
      removable: null
    }
  },

  methods: {
    loadAsyncData () {
      const params = [
        `sortBy=${this.sort.by}`,
        `sortOrder=${this.sort.order}`,
        `itemsPerPage=${this.itemsPerPage}`,
        `page=${this.page}`
      ].join('&')

      this.loading = true

      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/user/addresses?${params}`)
        .then((res) => {
          this.table.data = res.data
        })
        .catch((e) => {
          this.table.data = []
          this.table.meta.total = 0
        })
        .finally(() => (this.loading = false))
    },

    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },

    onSort (field, order) {
      this.sort.by = field
      this.sort.order = order
      this.loadAsyncData()
    },

    removeCommas (str) {
      str = str.replace(/^\s+|\s+$/gm, '').replace(', ,', ',')

      if (str.startsWith(',')) {
        str = str.slice(1)
      }

      if (str.endsWith(',')) {
        str = str.slice(0, -1)
      }

      return str
    },

    toggleRemoveModal (address = null) {
      this.modal.active = !this.modal.active
      this.removable = address
    },

    removed (res) {
      const index = this.table.data.map(item => item.id).indexOf(this.removable.id)
      this.table.data.splice(index, 1)
      this.table.meta.total--
      this.toggleRemoveModal()
      this.notification.active = true
      this.notification.message = res.message
      this.notification.class = 'is-success'
    }
  },

  head () {
    return {
      title: this.$t('pages.user.addresses.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.addresses.description')
        }
      ]
    }
  }
}
