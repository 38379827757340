
import UserSideMenu from '@/components/user/SideMenu'

export default {
  components: {
    UserSideMenu
  },

  middleware: 'auth',

  data () {
    return {
      loading: false,
      notification: {
        active: false,
        message: '',
        class: ''
      },
      passwords: {
        // current: '',
        new: '',
        confirmation: ''
      },
      error: '',
      show: {
        // current: false,
        new: false,
        confirmation: false
      },
      defaultErrors: {
        // current: [],
        new: [],
        confirmation: []
      },
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.user.index.title'),
          to: this.localePath('user'),
          active: false
        },
        {
          title: this.$t('pages.user.password.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  methods: {
    checkPassword (password) {
      return /^(?:([A-Z])*([a-z])*(\d)*(\W)*){8,12}$/.test(password)
    },

    prevalidate () {
      if (this.passwords.new.length < 8 ||
        this.passwords.confirmation.length < 8 ||
        this.passwords.new === this.passwords.confirmation
      ) {
        this.$store.dispatch('validation/setErrors', JSON.parse(JSON.stringify(this.defaultErrors)))

        return
      }

      this.validate()
    },

    validate () {
      const errors = JSON.parse(JSON.stringify(this.defaultErrors))

      // if (!this.passwords.current) {
      //   return
      // }

      if (this.passwords.new.length > 0 &&
        this.passwords.confirmation.length > 0 &&
        this.passwords.new !== this.passwords.confirmation
      ) {
        errors.new.unshift(this.$t('pages.user.password.new.errors.mismatch'))
        errors.confirmation.unshift(this.$t('pages.user.password.confirmation.errors.mismatch'))
      }

      if (this.passwords.new.length > 0 &&
        this.passwords.confirmation.length > 0 &&
        !this.checkPassword(this.passwords.new)
      ) {
        errors.new.unshift(this.$t('pages.user.password.new.errors.format'))
      }

      if (this.passwords.new.length > 0 &&
        this.passwords.confirmation.length > 0 &&
        !this.checkPassword(this.passwords.confirmation)
      ) {
        errors.confirmation.unshift(this.$t('pages.user.password.confirmation.errors.format'))
      }

      if (this.passwords.new.length < 8) {
        errors.new.unshift(this.$t('pages.user.password.new.errors.length', { length: 8 }))
      }

      if (this.passwords.confirmation.length < 8) {
        errors.confirmation.unshift(this.$t('pages.user.password.confirmation.errors.length', { length: 8 }))
      }

      this.$store.dispatch('validation/setErrors', errors)
    },

    submit () {
      try {
        this.validate()

        if (// this.errors.current.length ||
          this.errors.new.length ||
          this.errors.confirmation.length
        ) {
          return
        }

        this.loading = true

        this.$axios.$post('auth/password/update', this.passwords)
          .then((res) => {
            this.notification.message = this.$t('pages.user.password.notifications.updated')
            this.notification.class = 'is-success'
            this.passwords.new = ''
            this.passwords.confirmation = ''
          })
          .catch((e) => {
            this.notification.message = this.$t('pages.user.password.notifications.error')
            this.notification.class = 'is-danger'
          })
          .finally(() => {
            this.notification.active = true
            this.loading = false
          })
      } catch (e) {
        // this.error = e.response.data.message
      }
    }
  },

  head () {
    return {
      title: this.$t('pages.user.password.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.user.password.description')
        }
      ]
    }
  }
}
