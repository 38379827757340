
import { mapGetters } from 'vuex'

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
// import vueAwesomeCountdown from 'vue-awesome-countdown'
import TextareaAutosize from 'vue-textarea-autosize'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'
import operating from '@/mixins/callcenter/operating.js'

Vue.use(VueTelInput)
// Vue.use(vueAwesomeCountdown)
Vue.use(TextareaAutosize)

export default {
  mixins: [
    vueTelInputAllCountries,
    operating
  ],

  data () {
    return {
      submitted: undefined,
      remaining: 60000, // 60 seconds
      form: {
        phone: {
          number: '',
          model: '',
          valid: false,
          focus: false,
          error: '',
          country: undefined
        },
        name: {
          value: '',
          error: ''
        },
        message: '',
        when: this.$moment().locale(this.$i18n.locale).toDate()
      },
      error: '',
      button: {
        loading: false
      }
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      cart: 'cart/cart',
      country: 'country',
      countries: 'countries'
    }),

    locale () {
      return this.$i18n.locales.find(locale => locale.code === this.$i18n.locale)
    }
  },

  // watch: {
  //   'form.when': {
  //     handler (newValue, oldValue) {

  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },

  created () {
    this.submitted = false
    this.form.phone.country = this.country
  },

  // mounted () {
  //   window.setInterval(() => {
  //     this.form.when = new Date()
  //   }, 1000)
  // },

  methods: {
    validateName (name) {
      if (name.value.length) {
        name.error = ''
      } else {
        name.error = this.$t('callcenter.callback.name.errors.required')
      }
    },

    validatePhone (phone) {
      if (phone.number && phone.number.length && phone.valid) {
        this.form.phone.error = ''
      } else if (phone.number && phone.number.length) {
        this.form.phone.error = this.$t('callcenter.callback.phone.errors.invalid')
      } else {
        this.form.phone.error = this.$t('callcenter.callback.phone.errors.required')
      }
    },

    onPhoneInput (number, phone) {
      this.form.phone.valid = phone.valid
      this.form.phone.number = phone.number

      if (typeof phone.valid !== 'undefined' && phone.valid !== true) {
        this.form.phone.error = this.$t('callcenter.callback.phone.errors.invalid')
      } else {
        this.form.phone.error = ''
      }
    },

    submit () {
      this.validateName(this.form.name)
      this.validatePhone(this.form.phone)

      if (this.form.phone.error.length || this.form.name.error.length) {
        this.button.loading = false

        return
      }

      this.button.loading = true

      const data = {
        name: this.form.name.value,
        phone: this.form.phone.number,
        message: this.form.message,
        when: this.form.when
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/callbacks`, data)
        // .then((res) => {
        //   console.log(res)
        // })
        .finally(() => {
          this.button.loading = false
          this.submitted = true
        })
    }
  }
}
