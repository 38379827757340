
import { mapGetters } from 'vuex'

import TradeinTradeinable from '@/components/tradein/Tradeinable'

export default {
  components: {
    TradeinTradeinable
  },

  middleware ({ store, redirect, app }) {
    if (!store.state.tradein.tradein.id) {
      return redirect(app.localePath({ name: 'tradein' }))
    }
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('pages.tradein.thanks.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      tradein: 'tradein/tradein'
    }),

    total () {
      return this.tradein.items.reduce((total, item) => {
        return total + Number(this.getEstimate(item))
      }, 0)
    },

    grandTotal () {
      return this.total
    },

    operating () {
      const schedule = this.callcenter.schedule.days
      const timeFormat = 'hh:mm'
      const operating = {
        message: '',
        class: ''
      }

      if (Array.isArray(schedule)) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday()
        const open = this.$moment(schedule[weekday - 1].open, timeFormat)
        const close = this.$moment(schedule[weekday - 1].close, timeFormat)

        if (moment.isBefore(open)) {
          operating.message = this.$t('pages.tradein.thanks.callback.true.today.notYet', {
            open: schedule[weekday - 1].open
          })
          operating.class = 'is-warning'
        } else if (moment.isBetween(open, close)) {
          operating.message = this.$t('pages.tradein.thanks.callback.true.today.operating')
          operating.class = 'is-success'
        } else {
          operating.message = this.$t('pages.tradein.thanks.callback.true.tomorrow', {
            open: schedule[weekday !== 7 ? weekday : 0].open
          })
          operating.class = 'is-warning'
        }
      } else if (schedule === '') {
        operating.message = this.$t('pages.tradein.thanks.callback.true.today.operating')
        operating.class = 'is-success'
      }

      return operating
    }
  },

  methods: {
    calculateAmount (item, percent) {
      return this.$n((item.product.price / 100 * percent).toFixed(item.currency.round))
    },

    getEstimate (item) {
      let percent = 0

      if (item.options.length > 0) {
        percent = item.options
          .filter(o => o.value && o.value.percent)
          .reduce((sum, o) => sum + o.value.percent, 0)
      }

      if (percent === 0) {
        return item.product.price
      } else if (percent > 0 && percent < 100) {
        return (item.product.price - (item.product.price / 100 * percent)).toFixed(item.currency.round)
      } else {
        return 0
      }
    }
  },

  head () {
    return {
      title: this.$t('pages.tradein.thanks.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.tradein.thanks.description', { tradein: this.tradein.id })
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex,nofollow'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
