
export default {
  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    },
    chosenFiltersLength: {
      required: true,
      default () {
        return 0
      },
      type: Number
    }
  },

  data () {
    return {
      active: this.value
    }
  },

  watch: {
    value: {
      handler (val) {
        this.active = val
      },
      immediate: true
    }
  }
}
