
export default {
  props: {
    total: {
      type: Number,
      default () {
        return 1
      },
      required: true
    },
    current: {
      type: Number,
      default () {
        return 1
      },
      required: true
    },
    page: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  computed: {
    pagination () {
      const pagination = {
        first: {
          page: 1,
          isActive: this.current === 1,
          component: this.current === 1 ? 'span' : 'nuxt-link',
          to: this.current === 1
            ? null
            : `${this.localePath({
                name: 'slug',
                params: { slug: this.page.slug }
              })}`
        },
        last: {
          page: this.total,
          isActive: this.current === this.total,
          component: this.current === this.total ? 'span' : 'nuxt-link',
          to: this.current === this.total
            ? null
            : `${this.localePath({
                name: 'slug',
                params: { slug: this.page.slug }
              })}?page=${this.total}`
        }
      }

      if (this.current > 1) {
        const previous = this.current - 1

        pagination.previous = {
          page: previous,
          isActive: false,
          component: 'nuxt-link',
          to: `${this.localePath({
            name: 'slug',
            params: { slug: this.page.slug }
          })}${previous > 1 ? `?page=${previous}` : ''}`
        }
      }

      if (this.current < this.total) {
        const next = this.current + 1

        pagination.next = {
          page: next,
          isActive: false,
          component: 'nuxt-link',
          to: `${this.localePath({
            name: 'slug',
            params: { slug: this.page.slug }
          })}?page=${next}`
        }
      }

      return pagination
    }
  }
}
