
import { mapGetters } from 'vuex'

import IndexJournalArticle from '@/components/index/JournalArticle'

export default {
  components: {
    IndexJournalArticle
  },

  computed: {
    ...mapGetters({
      country: 'country',
      journal: 'journal/index'
    })
  },

  async mounted () {
    await this.get()
  },

  methods: {
    async get () {
      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/journal`)
        .then((res) => {
          this.$store.dispatch('journal/setIndexTopics', res.data)
        })
    }
  }
}
