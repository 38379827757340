
import { mapGetters } from 'vuex'

import GoogleMap from '@/components/GoogleMap'
import ContactsShopCities from '@/components/contacts/ShopCities'

export default {
  components: {
    GoogleMap,
    ContactsShopCities
  },

  async asyncData ({ app }) {
    const response = await app.$axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/pages/contacts?city=${app.store.state.location.city.id}&latitude=${app.store.state.location.lat}&longitude=${app.store.state.location.lng}`)

    return {
      page: response.data.page,
      contacts: response.data.contacts
    }
  },

  data () {
    return {
      center: {},
      description: '',
      visible: [
        'shop',
        'pickup',
        'service'
      ],
      contactsByType: null
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      country: 'country',
      logo: 'logo',
      location: 'location'
    }),

    breadcrumbs () {
      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.page.header,
          to: '#',
          active: true
        }
      ]
    },

    contactsVisibleOnMap () {
      return this.contacts.filter(contact => this.visible.includes(contact.type) && contact.lat !== null && contact.lng !== null)
    }
  },

  created () {
    this.setCenter(this.contactsVisibleOnMap[0])

    this.contactsByType = this.contacts.reduce((prev, curr) => {
      prev[curr.type] = prev[curr.type] || {}
      prev[curr.type].values = prev[curr.type].values || []
      prev[curr.type].values.push(curr)
      prev[curr.type].value = prev[curr.type].value || curr
      prev[curr.type].city = prev[curr.type].city || curr.city

      return prev
    }, {})
  },

  methods: {
    setCenter (contact) {
      if (contact) {
        this.center = {
          lat: contact.lat,
          lng: contact.lng
        }
        this.description = contact.address
      }
    },

    generateSchedule (contact) {
      const schedule = []
      const vm = this

      if (contact.schedule.holiday) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday() - 1

        contact.schedule.days.forEach((day, index) => { day.index = index })

        contact.schedule.days.slice(weekday).concat(contact.schedule.days.slice(0, weekday)).forEach((day) => {
          const i = schedule.findIndex((x, key) => x.open === day.open && x.close === day.close && key === schedule.length - 1)

          if (i === -1) {
            schedule.push({
              days: vm.$moment(day.index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase(),
              open: day.open,
              close: day.close
            })
          } else {
            schedule[i].days = `${schedule[i].days.split('–')[0]}–${vm.$moment(day.index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase()}`
          }
        })
      } else {
        contact.schedule.days.forEach(function (item, index) {
          const i = schedule.findIndex((x, key) => x.open === item.open && x.close === item.close && key === schedule.length - 1)

          if (i === -1) {
            schedule.push({
              days: vm.$moment(index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase(),
              open: item.open,
              close: item.close
            })
          } else {
            schedule[i].days = `${schedule[i].days.split('–')[0]}–${vm.$moment(index + 1, 'E').locale(vm.$i18n.locale).format('ddd').toUpperCase()}`
          }
        })
      }

      return schedule
    },

    scheduleHasMessage (schedule) {
      return schedule.message && schedule.message.replace(/(<([^>]+)>)/gi, '') !== ''
    },

    changeCity (type, city) {
      this.contactsByType[type].value = this.contactsByType[type].values.find(item => item.city.id === city.id)

      if (['shop'].includes(type)) {
        this.contactsByType[type].city = city
        this.setCenter(this.contactsByType[type].value)
      }
    }
  },

  head () {
    return {
      title: this.page.title,
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: `${this.$config.appUrl}/${this.page.canonical}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.description
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.page.schema_org_title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.schema_org_description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.page.robots
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.page.keywords
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: this.page.schemaOrg.contacts,
          body: true
        }
      ]
    }
  }
}
