
export default {
  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    }
  },

  data () {
    return {
      chosenFilters: this.value
    }
  },

  watch: {
    value: {
      handler (val) {
        this.chosenFilters = val
      },
      immediate: true
    }
  },

  methods: {
    chosenFilterComponent (ref) {
      if (this.$refs[ref] === undefined) {
        return false
      }

      return this.$refs[ref][0].clientWidth < this.$refs[ref][0].scrollWidth
    }
  }
}
