
import { mapGetters } from 'vuex'

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

Vue.use(VueTelInput)

export default {
  mixins: [
    vueTelInputAllCountries
  ],

  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: {
          number: '',
          valid: true,
          country: undefined
        },
        password: '',
        password_confirmation: '',
        loading: false
      },
      error: '',
      show: {
        password: false,
        confirmation: false
      }
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      compare: 'compare/compare',
      country: 'country',
      countries: 'countries'
    })
  },

  created () {
    this.form.phone.country = this.country
  },

  methods: {
    onPhoneInput (number, phone) {
      this.form.phone.number = phone.number

      if (typeof phone.valid !== 'undefined' && phone.valid !== true) {
        this.form.phone.valid = false
      } else {
        this.form.phone.valid = true
      }
    },

    async submit () {
      if (!this.form.phone.valid) {
        return
      }

      this.form.loading = true

      try {
        await this.$axios.post('auth/register', {
          name: this.form.name,
          lastname: this.form.lastname,
          patronymic: this.form.patronymic,
          phone: this.form.phone.number,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation
        })

        await this.$auth.loginWith('laravelJWT', {
          data: {
            login: this.form.phone.number,
            password: this.form.password,
            cart: this.cart,
            compare: this.compare,
            country: this.country
          }
        })
          .then(() => {
            this.$emit('modalActiveToggle', false)
          })
      } catch (error) {
        this.form.loading = false
      }
    }
  }
}
