
import { mapGetters } from 'vuex'

import Vue from 'vue'
import TextareaAutosize from 'vue-textarea-autosize'

import Comments from '@/components/Comments'
import ProductPartialsVotes from '@/components/product/partials/Votes'

Vue.use(TextareaAutosize)

export default {
  components: {
    Comments,
    ProductPartialsVotes
  },

  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      notification: {
        active: false,
        message: '',
        class: ''
      },
      formIsActive: false,
      questions: [],
      question: {
        default: {
          body: '',
          anonymous: false,
          user: {
            email: (this.$auth.loggedIn || this.$auth.user) ? this.$auth.user.email : ''
          },
          errors: {
            email: [],
            recaptcha: []
          }
        },
        current: {
          body: '',
          anonymous: false,
          user: {
            email: (this.$auth.loggedIn || this.$auth.user) ? this.$auth.user.email : ''
          },
          errors: {
            email: [],
            recaptcha: []
          }
        }
      },
      page: 0,
      pages: undefined,
      reachedLastPage: false,
      loading: true,
      asking: false
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  async created () {
    if (process.client) {
      await this.getQuestions()
    }
  },

  methods: {
    async getQuestions () {
      if (typeof this.pages !== 'undefined' && this.page >= this.pages) {
        return
      }

      this.page += 1
      this.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/product/${this.product.store.id}/questions?page=${this.page}`, { progress: false })
        .then((res) => {
          this.pages = res.meta.last_page
          this.reachedLastPage = this.page === this.pages
          this.questions = [...this.questions, ...res.data]
        })
        .finally(() => {
          this.loading = false
        })
    },

    getDiffFromDateFormatted (date) {
      return this.$moment.duration(
        this.$moment(date).locale(this.$i18n.locale).diff(this.$moment().locale(this.$i18n.locale))
      ).humanize(true)
    },

    async validateEmail (email = this.question.current.user.email) {
      this.question.current.errors.email = []

      if (email === '') {
        this.question.current.errors.email.push(this.$t('auth.email.errors.required'))
      } else if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        try {
          await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/user/${email}`, { progress: false })

          this.question.current.errors.email = []
        } catch (error) {
          // this.question.current.errors.email.push(this.$t('auth.email.errors.notFound'))
        }

        return
      }

      this.question.current.errors.email.push(this.$t('auth.email.errors.invalidFormat', { email }))
    },

    validateBody (body = this.question.current.body) {
      this.question.current.errors.body = []

      if (body === '') {
        this.question.current.errors.body.push(this.$t('product.tabs.questions.errors.body.required'))
      }
    },

    recaptchaOnError (e) {
      this.question.current.errors.recaptcha.push(this.$t('recaptcha.errors.required'))
    },

    recaptchaOnSuccess (token) {
      this.validateBody()

      if (this.question.current.errors.email.length || this.question.current.errors.body.length) {
        return
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/product/${this.product.store.id}/questions`, this.question.current, { progress: false })
        .then((res) => {
          this.questions.unshift(res.data)
          this.notification.message = this.$t('product.tabs.questions.notifications.submitted')
          this.notification.class = 'is-success'
        })
        .catch((e) => {
          this.notification.message = this.$t('product.tabs.questions.notifications.error')
          this.notification.class = 'is-danger'
        })
        .finally(() => {
          Object.assign(this.question.current, this.question.default)
          this.notification.active = true
          this.asking = false
        })
    },

    recaptchaOnExpired () {
      //
    },

    async askQuestion () {
      this.asking = true

      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
      } catch (e) {
        this.asking = false
      }
    },

    voted (data) {
      this.questions.find(question => question.id === data.model.id).votes = data.votes
    }
  }
}
