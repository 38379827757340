
export default {
  methods: {
    open () {
      this.$store.dispatch('catalog/setMobileMenuActive', true)

      try {
        document.getElementById('mobile-categories-menu').firstChild.click()
      } catch (e) {
        //
      }
    }
  }
}
