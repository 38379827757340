
export default {
  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    },
    slug: {
      required: true,
      default () {
        return ''
      },
      type: String
    },
    path: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    pathWithParams: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    chosenFiltersLength: {
      required: true,
      default () {
        return 0
      },
      type: Number
    },
    chosenFilterValues: {
      required: true,
      default () {
        return []
      },
      type: Array
    }
  },

  data () {
    return {
      categories: this.value
    }
  },

  watch: {
    value: {
      handler (val) {
        this.categories = val
      },
      immediate: true
    }
  },

  methods: {
    getCategoryCount (category) {
      if (this.chosenFiltersLength > 0 && !this.chosenFilterValues.includes(category.title)) {
        return `+${category.count}`
      } else {
        return category.count
      }
    },

    getPath (params) {
      const path = JSON.parse(JSON.stringify(params ? this.pathWithParams : this.path))

      if (params) {
        path.params.params = params
      }

      return this.localePath(path)
    }
  }
}
