
export default {
  props: {
    icon: {
      required: true,
      type: Object,
      default: () => ({
        type: '',
        set: '',
        value: ''
      })
    },
    size: {
      required: false,
      type: String,
      default: () => 'is-medium'
    }
  }
}
