
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from '@vue/composition-api'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  components: {
    LazyMenusMobileMenuDropdown: defineAsyncComponent(() => import('@/components/menus/MobileMenuDropdown'))
  },

  mixins: [
    clickaway
  ],

  computed: {
    ...mapGetters({
      menu: 'catalog/mobile'
    })
  },

  watch: {
    'menu.active': {
      handler (val) {
        const html = document.documentElement

        if (val) {
          html.style.overflow = 'hidden'
        } else {
          html.style.overflow = null
        }
      },
      immediate: false,
      deep: true
    }
  },

  methods: {
    open () {
      this.$store.dispatch('catalog/setMobileMenuActive', !this.menu.active)

      const navSecondary = document.getElementById('nav-secondary')

      if (navSecondary) {
        navSecondary.style.zIndex = this.menu.active ? '9999' : '999'
      }
    },

    away () {
      this.$store.dispatch('catalog/setMobileMenuActive', false)
    }
  }
}
