
import { mapGetters } from 'vuex'

import Vue from 'vue'
import TextareaAutosize from 'vue-textarea-autosize'

import Comments from '@/components/Comments'
import ProductPartialsVotes from '@/components/product/partials/Votes'

Vue.use(TextareaAutosize)

export default {
  components: {
    Comments,
    ProductPartialsVotes
  },

  props: {
    store: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data () {
    return {
      notification: {
        active: false,
        message: '',
        class: ''
      },
      formIsActive: false,
      reviews: [],
      review: {
        default: {
          stars: {
            mouseover: 0,
            click: 0
          },
          rating: 0,
          body: '',
          pros: '',
          cons: '',
          anonymous: false,
          user: {
            name: (this.$auth.loggedIn || this.$auth.user) ? this.$auth.user.name : '',
            email: (this.$auth.loggedIn || this.$auth.user) ? this.$auth.user.email : ''
          },
          errors: {
            name: [],
            email: [],
            stars: [],
            recaptcha: []
          }
        },
        current: {
          stars: {
            mouseover: 0,
            click: 0
          },
          rating: 0,
          body: '',
          pros: '',
          cons: '',
          anonymous: false,
          user: {
            name: (this.$auth.loggedIn || this.$auth.user) ? this.$auth.user.name : '',
            email: (this.$auth.loggedIn || this.$auth.user) ? this.$auth.user.email : ''
          },
          errors: {
            name: [],
            email: [],
            stars: [],
            recaptcha: []
          }
        }
      },
      page: 0,
      pages: undefined,
      reachedLastPage: false,
      loading: true,
      reviewing: false
    }
  },

  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  created () {
    if (process.client) {
      this.getReviews()
    }
  },

  methods: {
    async getReviews () {
      if (typeof this.pages !== 'undefined' && this.page >= this.pages) {
        return
      }

      this.page += 1
      this.loading = true

      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/stores/${this.store.id}/reviews?page=${this.page}`, { progress: false })
        .then((res) => {
          this.pages = res.meta.last_page
          this.reachedLastPage = this.page === this.pages
          this.reviews = [...this.reviews, ...res.data]
        })
        .finally(() => {
          this.loading = false
        })
    },

    validateName (name = this.review.current.user.name) {
      this.review.current.errors.name = []

      if (name === '') {
        this.review.current.errors.name.push(this.$t('auth.name.errors.required'))
      }
    },

    async validateEmail (email = this.review.current.user.email) {
      this.review.current.errors.email = []

      if (email === '') {
        this.review.current.errors.email.push(this.$t('auth.email.errors.required'))
      } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        this.review.current.errors.email.push(this.$t('auth.email.errors.invalidFormat', { email }))
      } else {
        await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/user/${email}`, { progress: false })
          .then((res) => {
            this.review.current.errors.email = []
          })
      }
    },

    validateStars () {
      this.review.current.errors.stars = []

      if (this.review.current.rating === 0) {
        this.review.current.errors.stars.push(this.$t('store.tabs.reviews.errors.stars.required'))
      }
    },

    starsMouseOver (stars) {
      this.review.current.stars.mouseover = stars

      if (this.review.current.stars.mouseover < this.review.current.stars.click) {
        this.review.current.stars.click = 0
      }
    },

    starsMouseLeave (stars) {
      this.review.current.stars.mouseover = 0
      this.review.current.stars.click = this.review.current.rating
    },

    starsClick (stars) {
      this.review.current.rating = stars
      this.review.current.stars.click = stars
      this.validateStars()
    },

    recaptchaOnError (e) {
      this.review.current.errors.recaptcha.push(this.$t('recaptcha.errors.required'))
    },

    async recaptchaOnSuccess (token) {
      this.validateName()
      await this.validateEmail()
      this.validateStars()

      if (this.review.current.errors.name.length ||
        this.review.current.errors.email.length ||
        this.review.current.errors.stars.length
      ) {
        this.reviewing = false

        return
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/stores/${this.store.id}/reviews`, this.review.current, { progress: false })
        .then((res) => {
          this.reviews.unshift(res.data)
          this.notification.message = this.$t('store.tabs.reviews.notifications.submitted')
          this.notification.class = 'is-success'
        })
        .catch((e) => {
          this.notification.message = this.$t('store.tabs.reviews.notifications.error')
          this.notification.class = 'is-danger'
        })
        .finally(() => {
          Object.assign(this.review.current, this.review.default)
          this.notification.active = true
          this.reviewing = false
        })
    },

    getDiffFromDateFormatted (date) {
      return this.$moment.duration(
        this.$moment(date).locale(this.$i18n.locale).diff(this.$moment().locale(this.$i18n.locale))
      ).humanize(true)
    },

    recaptchaOnExpired () {
      //
    },

    async submitReview () {
      this.reviewing = true

      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
      } catch (e) {
        //
      }
    },

    voted (data) {
      this.reviews.find(review => review.id === data.model.id).votes = data.votes
    }
  }
}
