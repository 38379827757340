
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    index: {
      required: true,
      default () {
        return 0
      },
      type: Number
    }
  },

  data () {
    return {
      loading: false,
      item: this.value,
      active: false,
      query: ''
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    })
  },

  watch: {
    value: {
      handler (val) {
        this.item = val
      },
      immediate: true
    },

    'item.characteristic.value': {
      handler (val) {
        if (!val) {
          return
        }

        this.loading = true

        this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/tradein/categories/${this.item.category.id}/characteristics/${this.item.characteristic.id}/values/${this.item.characteristic.value.id}/products`)
          .then((res) => {
            this.$store.dispatch('tradein/setProducts', {
              index: this.index,
              products: res.data
            })

            this.loading = false
          })
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    toggle () {
      this.active = !this.active

      if (this.$refs.product) {
        this.$nextTick(() => {
          this.$refs.product.focus()
        })
      }
    },

    hide () {
      this.active = false
    },

    setProduct (product) {
      this.query = ''
      this.$store.dispatch('tradein/setProduct', {
        index: this.index,
        product
      })
      this.hide()
      // this.$emit('input', this.item)
    },

    clearProduct () {
      this.query = ''
      this.$store.dispatch('tradein/setProduct', {
        index: this.index,
        product: null
      })
    }
  }
}
