
export default {
  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: null
    },
    slug: {
      required: true,
      default () {
        return ''
      },
      type: String
    },
    path: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    pathWithParams: {
      required: true,
      default () {
        return {}
      },
      type: Object
    }
  },

  data () {
    return {
      badges: this.value
    }
  },

  watch: {
    value: {
      handler (val) {
        this.badges = val
      },
      immediate: true
    }
  },

  methods: {
    getPath (params) {
      const path = JSON.parse(JSON.stringify(params ? this.pathWithParams : this.path))

      if (params) {
        path.params.params = params
      }

      return this.localePath(path)
    }
  }
}
