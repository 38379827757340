
import { mapGetters } from 'vuex'

import Vue from 'vue'

import ProductCard from '@/components/product/Card'

if (process.client) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
  Vue.use(VueAwesomeSwiper)
}

export default {
  components: {
    ProductCard
  },

  props: {
    title: {
      type: String,
      default () {
        return this.$t('pages.index.products.sale.title')
      },
      required: false
    }
  },

  data () {
    return {
      options: {
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          watchOverflow: true,
          clickable: true
        },
        spaceBetween: 0,
        // simulateTouch: false,
        firstSlideReached: true,
        lastSlideReached: false,
        // allowTouchMove: false,
        on: {
          slideChange: () => {
            this.edgeReached()
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      products: 'sale/products'
    }),

    visibleSlidesPerView () {
      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        return 2
      } else if (['tablet'].includes(this.$mq)) {
        return 3
      } else if (['desktop'].includes(this.$mq)) {
        return 4
      } else if (['widescreen'].includes(this.$mq)) {
        return 5
      } else {
        return 6
      }
    },

    hideButtons () {
      if (['mobileSmall', 'mobile'].includes(this.$mq) && this.products.length <= 2) {
        return true
      } else if (['tablet'].includes(this.$mq) && this.products.length <= 3) {
        return true
      } else if (['desktop'].includes(this.$mq) && this.products.length <= 4) {
        return true
      } else if (['widescreen'].includes(this.$mq) && this.products.length <= 5) {
        return true
      } else if (['fullhd'].includes(this.$mq) && this.products.length <= 6) {
        return true
      } else {
        return false
      }
    }
  },

  async mounted () {
    await this.get()
  },

  methods: {
    slide (index) {
      this.swiper.slideTo(index, 1000, false)

      this.edgeReached()
    },

    edgeReached () {
      this.options.firstSlideReached = this.swiper.snapIndex === 0

      this.options.lastSlideReached = this.swiper.slides.length - this.swiper.snapIndex - this.visibleSlidesPerView === 0
    },

    async get () {
      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/products/sale--true_available--true_sort--none`)
        .then((res) => {
          this.$store.dispatch('sale/setProducts', res.data)
        })
    }
  }
}
